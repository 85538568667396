.registration {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  background-color: rgba(61, 73, 16, 0.1);
  overflow-y: auto;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .modal-window {
      position: relative;
      width: 65%;
      max-width: 1248px;
      padding: 30px 60px;
      border-radius: 15px;
      background-color: #FFFFFF;
      box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
  
      .heading-one {
        font-family: Inter;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
      }
  
      .close {
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: translate(40%, -40%);
        -moz-transform: translate(40%, -40%);
        -o-transform: translate(40%, -40%);
        -ms-transform: translate(40%, -40%);
        transform: translate(40%, -40%);
        border-radius: 15px;
        border: none;
        padding: 8px;
        background-color: #3D4910;
        cursor: pointer;
  
        img {
          display: block;
          width: 14px;
        }
      }
  
      .registration-form {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
  
        .registration-form-fields {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
  
          .ordinary-input-wrapper {
            &:nth-child(1) {
              width: 70%;
            }
  
            &:nth-child(2) {
              width: 28%;
            }
  
            &:nth-child(3) {
              width: 100%;
            }
  
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(9),
            &:nth-child(10) {
              width: 49%;
            }
  
            &:nth-child(7),
            &:nth-child(8) {
              width: 32%;
            }
          }
  
          .phone-input-wrapper {
            &:nth-child(6) {
              width: 32%;
            }
          }
        }
  
        button[type="submit"] {
          align-self: center;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .registration {
    .container {
      .modal-window {
        width: 67.5%;
    
        .heading-one {
          font-size: 37px;
          line-height: 45px;
        }
    
        .registration-form {
          padding-top: 35px;
    
          .registration-form-fields {
            .ordinary-input-wrapper {
              input {
                padding: 7.5px 15px;
              }
  
              .password-container {
                input {
                  padding: 7.5px 45px 7.5px 15px;
                }
              }
            }
  
            .phone-input-wrapper {
              .phone-input-container {
                input[class="form-control "],
                input[class="form-control invalid"],
                input[class="form-control open "],
                input[class="form-control open invalid"] {
                  min-height: 41px;
                  padding: 7.5px 15px 7.5px 65px;
                }
              }
            }
          }
    
          button[type="submit"] {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .registration {
    .container {
      .modal-window {
        width: 70%;
        padding: 25px 50px;
    
        .heading-one {
          font-size: 35px;
          line-height: 43px;
        }
    
        .registration-form {
          .registration-form-fields {
            .ordinary-input-wrapper {
              .password-container {
                input {
                  padding: 7.5px 42.5px 7.5px 15px;
                }
              }
            }
  
            .phone-input-wrapper {
              .phone-input-container {
                input[class="form-control "],
                input[class="form-control invalid"],
                input[class="form-control open "],
                input[class="form-control open invalid"] {
                  min-height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .registration {
    .container {
      .modal-window {
        width: 84%;
    
        .heading-one {
          font-size: 33px;
          line-height: 41px;
        }
    
        .registration-form {
          padding-top: 30px;
    
          .registration-form-fields {
            .ordinary-input-wrapper {
              input {
                padding: 5px 15px;
              }
  
              .password-container {
                input {
                  padding: 5px 42.5px 5px 15px;
                }
              }
            }
  
            .phone-input-wrapper {
              .phone-input-container {
                input[class="form-control "],
                input[class="form-control invalid"],
                input[class="form-control open "],
                input[class="form-control open invalid"] {
                  min-height: 34px;
                  height: 34px;
                  padding: 5px 15px 5px 65px;
                }
              }
            }
          }
    
          button[type="submit"] {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .registration {
    .container {
      .modal-window {
        width: 93%;
        padding: 20px 40px;
    
        .heading-one {
          font-size: 30px;
          line-height: 38px;
        }
    
        .close {
          border-radius: 13px;
          padding: 7px;
    
          img {
            width: 12px;
          }
        }
    
        .registration-form {
          padding-top: 25px;
    
          .registration-form-fields {
            .ordinary-input-wrapper {
              .password-container {
                input {
                  padding: 5px 40px 5px 15px;
                }
              }
            }
  
            .phone-input-wrapper {
              .phone-input-container {
                input[class="form-control "],
                input[class="form-control invalid"],
                input[class="form-control open "],
                input[class="form-control open invalid"] {
                  min-height: 32px;
                  height: 32px;
                  padding: 5px 15px 5px 52px;
                }
              }

              .flag-container {
                .selected-flag {
                  width: 30px;
                  padding: 0;
                }
              }
            }
          }
    
          button[type="submit"] {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .registration {
    .container {
      .modal-window {
        width: 100%;
        height: 100%;
        padding: 75px 60px 60px;
        border-radius: 0;
    
        .heading-one {
          font-size: 27px;
          line-height: 35px;
        }
    
        .close {
          -webkit-transform: translate(-100%, 100%);
          -moz-transform: translate(-100%, 100%);
          -o-transform: translate(-100%, 100%);
          -ms-transform: translate(-100%, 100%);
          transform: translate(-100%, 100%);
          border-radius: 15px;
          padding: 8px;
    
          img {
            width: 14px;
          }
        }
    
        .registration-form {
          padding-top: 20px;
    
          .registration-form-fields {
            .ordinary-input-wrapper {
              &:nth-child(1) {
                width: 64%;
              }
    
              &:nth-child(2) {
                width: 34%;
              }
    
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                width: 100%;
              }

              &:nth-child(7),
              &:nth-child(9),
              &:nth-child(10) {
                width: 49%;
              }

              &:nth-child(8) {
                width: 100%;
              }
            }

            .phone-input-wrapper {
              &:nth-child(6) {
                width: 49%;
              }

              .phone-input-container {
                input[class="form-control "],
                input[class="form-control invalid"],
                input[class="form-control open "],
                input[class="form-control open invalid"] {
                  min-height: 31px;
                  height: 31px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 601px) {
  .registration {
    .container {
      height: max-content;

      .modal-window {
        padding: 60px 50px 50px;
    
        .heading-one {
          font-size: 24px;
          line-height: 32px;
        }

        .close {
          -webkit-transform: translate(-50%, 50%);
          -moz-transform: translate(-50%, 50%);
          -o-transform: translate(-50%, 50%);
          -ms-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
        }
    
        .registration-form {
          .registration-form-fields {
            .ordinary-input-wrapper {
              width: 100% !important;
            }

            .phone-input-wrapper {
              width: 100% !important;

              .phone-input-container {
                input[class="form-control "],
                input[class="form-control invalid"],
                input[class="form-control open "],
                input[class="form-control open invalid"] {
                  min-height: 30px;
                  height: 30px;
                }
              }
            }
          }
    
          button[type="submit"] {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .registration {
    .container {
      .modal-window {
        padding: 60px 40px 40px;
      }
    }
  }
}