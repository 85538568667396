.vip-company-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding-top: 80px;

  a {
    width: -webkit-calc((100% - 90px)/4);
    width: -moz-calc((100% - 90px)/4);
    width: -o-calc((100% - 90px)/4);
    width: -ms-calc((100% - 90px)/4);
    width: calc((100% - 90px)/4);
  } 
}

.vip-companies-not-found {
  padding-top: 40px;
  font-family: Inter;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

@media (max-width: 1600px) {
  .vip-company-list {
    padding-top: 65px;
  }
  
  .vip-companies-not-found {
    padding-top: 35px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 1440px) {
  .vip-company-list {
    padding-top: 55px;
  }
  
  .vip-companies-not-found {
    padding-top: 30px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1280px) {
  .vip-company-list {
    gap: 25px;
    padding-top: 45px;
  
    a {
      width: -webkit-calc((100% - 50px)/3);
      width: -moz-calc((100% - 50px)/3);
      width: -o-calc((100% - 50px)/3);
      width: -ms-calc((100% - 50px)/3);
      width: calc((100% - 50px)/3);
    }
  }
  
  .vip-companies-not-found {
    padding-top: 25px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .vip-company-list {
    display: block;
    padding-top: 15px;

    .slick-track {
      .slick-slide {
        a {
          display: block !important;
          padding: 15px;
          cursor: default;
        }
      }
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      bottom: -35px;
    }
  }
  
  .vip-companies-not-found {
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 834px) {
  .vip-company-list {
    padding-top: 12.5px;

    .slick-track {
      .slick-slide {
        a {
          padding: 10px;
        }
      }
    }

    .slick-dots {
      bottom: -30px;
    }
  }
  
  .vip-companies-not-found {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 601px) {
  .vip-company-list {
    padding-top: 5px;

    .slick-dots {
      bottom: -20px;
    }
  }
  
  .vip-companies-not-found {
    padding-top: 15px;
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 360px) {
  .vip-company-list {
    .slick-dots {
      li {
        margin: 0;
      }
    }
  }
}