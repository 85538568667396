.Navbar,
.NavbarInfo,
.NavbarExit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Navbar {
  justify-content: space-between;
  position: absolute;
  width: 100vw;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  background: #D9D9D9;
}

.NavbarInfo {
  width: 100%;
  height: 100%;
}

.NavbarExit {
  width: 200px;
  height: 100%;
  cursor: pointer;
}