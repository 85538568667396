.Advertising {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px;
    margin: 10px;
    p:first-child {width: 200px}
    p:nth-child(2) {width: 400px}
    p:nth-child(3) {width: 300px}
    p:nth-child(4) {width: 200px}

    p {
        min-width: 150px;
        margin: 10px;
    }
    input {
        min-width: 150px;
        margin: 10px;
    }
}