.ForceMajeureCertification {
  background-color: #F7F8F4;

  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .force-majeure-certification-content {
      padding: 80px 0;

      .heading-one,
      .heading-two {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }

      .force-majeure-certification-content-top-row {
        display: flex;
        align-items: flex-start;
        column-gap: 17.5%;

        .previous-page-link {
          font-family: Inter;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-decoration: none;
          text-transform: uppercase;
        }

        .force-majeure-certification-content-top-row__right-side {
          width: 60%;

          .paragraph {
            padding-top: 30px;
            font-family: Inter;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: justify;

            &:first-child {
              padding-top: 0;
            }
          }

          .bold-font {
            font-weight: 600;
          }

          ul {
            li {
              margin-left: 20px;
              font-family: Inter;
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
              text-align: justify;
            }
          }

          .introduction {
            padding: 80px 0;
          }

          .buttons {
            display: flex;
            column-gap: 40px;
          }

          .explanation {
            padding-top: 80px;
          }
        }
      }
      
      .heading-two_form {
        padding-top: 80px;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .force-majeure-certification-content {
        padding: 70px 0;
  
        .heading-one,
        .heading-two {
          font-size: 56px;
          line-height: 68px;
        }
  
        .description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        .force-majeure-certification-content-top-row {
          column-gap: 15%;

          .previous-page-link {
            font-size: 19px;
            line-height: 28px;
          }
  
          .force-majeure-certification-content-top-row__right-side {
            width: 65%;

            .paragraph {
              padding-top: 25px;
              font-size: 17px;
              line-height: 24px;
            }

            ul {
              li {
                font-size: 17px;
                line-height: 24px;
              }
            }
  
            .introduction {
              padding: 70px 0;
            }
  
            .explanation {
              padding-top: 70px;
            }
          }
        }
        
        .heading-two_form {
          padding-top: 70px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .force-majeure-certification-content {
        padding: 60px 0;
  
        .heading-one,
        .heading-two {
          font-size: 48px;
          line-height: 63px;
        }
  
        .description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        .force-majeure-certification-content-top-row {
          .previous-page-link {
            font-size: 18px;
            line-height: 26px;
          }
  
          .force-majeure-certification-content-top-row__right-side {
            .paragraph {
              font-size: 16px;
              line-height: 23px;
            }

            ul {
              li {
                font-size: 16px;
                line-height: 23px;
              }
            }
  
            .introduction {
              padding: 60px 0;
            }
  
            .buttons {
              column-gap: 35px;
            }
  
            .explanation {
              padding-top: 60px;
            }
          }
        }
        
        .heading-two_form {
          padding-top: 60px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .force-majeure-certification-content {
        padding: 50px 0;
  
        .heading-one,
        .heading-two {
          font-size: 40px;
          line-height: 58px;
        }
  
        .description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        .force-majeure-certification-content-top-row {
          column-gap: 12.5%;

          .previous-page-link {
            font-size: 17px;
            line-height: 24px;
          }
  
          .force-majeure-certification-content-top-row__right-side {
            width: 70%;

            .paragraph {
              padding-top: 20px;
              font-size: 15px;
              line-height: 22px;
            }

            ul {
              li {
                font-size: 15px;
                line-height: 22px;
              }
            }
  
            .introduction {
              padding: 50px 0;
            }
  
            .explanation {
              padding-top: 50px;
            }
          }
        }
        
        .heading-two_form {
          padding-top: 50px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .force-majeure-certification-content {
        padding: 40px 0;
  
        .heading-one,
        .heading-two {
          font-size: 32px;
          line-height: 48px;
        }
  
        .description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        .force-majeure-certification-content-top-row {
          justify-content: space-between;
          column-gap: 0;
  
          .previous-page-link {
            font-size: 16px;
            line-height: 22px;
          }
  
          .force-majeure-certification-content-top-row__right-side {
            width: 75%;
  
            .paragraph {
              padding-top: 15px;
              font-size: 14px;
              line-height: 20px;
            }

            ul {
              li {
                font-size: 14px;
                line-height: 20px;
              }
            }
  
            .introduction {
              padding: 40px 0;
            }
  
            .buttons {
              column-gap: 30px;
            }
  
            .explanation {
              padding-top: 40px;
            }
          }
        }
        
        .heading-two_form {
          padding-top: 40px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;
      
      .force-majeure-certification-content {
        padding: 30px 0;
  
        .heading-one,
        .heading-two {
          font-size: 25px;
          line-height: 36px;
        }
  
        .description {
          font-size: 14px;
          line-height: 20px;
        }
  
        .force-majeure-certification-content-top-row {
          .previous-page-link {
            font-size: 14px;
            line-height: 18px;
          }
  
          .force-majeure-certification-content-top-row__right-side {
            width: 80%;
  
            .paragraph {
              font-size: 13px;
              line-height: 19px;
            }

            ul {
              li {
                font-size: 13px;
                line-height: 19px;
              }
            }
  
            .introduction {
              padding: 30px 0;
            }
  
            .explanation {
              padding-top: 30px;
            }
          }
        }
        
        .heading-two_form {
          padding-top: 30px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      .force-majeure-certification-content {
        .force-majeure-certification-content-top-row {
          display: block;
  
          .force-majeure-certification-content-top-row__right-side {
            width: 100%;
            padding-top: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .force-majeure-certification-content {
        padding: 20px 0;
  
        .heading-one,
        .heading-two {
          font-size: 18px;
          line-height: 24px;
        }
  
        .description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        .force-majeure-certification-content-top-row {
          .previous-page-link {
            font-size: 12px;
            line-height: 14px;
          }
  
          .force-majeure-certification-content-top-row__right-side {
            padding-top: 20px;
  
            .paragraph {
              padding-top: 10px;
              font-size: 12px;
              line-height: 18px;
            }

            ul {
              li {
                font-size: 12px;
                line-height: 18px;
              }
            }
  
            .introduction {
              padding: 20px 0;
            }
  
            .buttons {
              column-gap: 25px;
            }
  
            .explanation {
              padding-top: 20px;
            }
          }
        }
        
        .heading-two_form {
          padding-top: 20px;
        }
      }
    }
  }

  @media (max-width: 375px) {
    .wrapper {
      .force-majeure-certification-content {
        .force-majeure-certification-content-top-row {
          .force-majeure-certification-content-top-row__right-side {
            .buttons {
              a {
                padding: 10px 12.5px;
              }
            }
          }
        }
      }
    }
  }
}