.ServiceOrder {
  background-color: #F7F8F4;
  
  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .service-order-content {
      padding: 80px 0;

      .heading-one {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .service-order-content {
        padding: 70px 0;
  
        .heading-one {
          font-size: 56px;
          line-height: 68px;
        }

        .description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .service-order-content {
        padding: 60px 0;
  
        .heading-one {
          font-size: 48px;
          line-height: 63px;
        }

        .description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .service-order-content {
        padding: 50px 0;
  
        .heading-one {
          font-size: 40px;
          line-height: 58px;
        }

        .description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .service-order-content {
        padding: 40px 0;
  
        .heading-one {
          font-size: 32px;
          line-height: 48px;
        }

        .description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .service-order-content {
        padding: 30px 0;
  
        .heading-one {
          font-size: 25px;
          line-height: 36px;
        }

        .description {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .service-order-content {
        padding: 20px 0;
  
        .heading-one {
          font-size: 18px;
          line-height: 24px;
        }

        .description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}