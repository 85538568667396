.language-switcher {
  position: relative;
  z-index: 1;

  button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;

    span {
      min-width: 55px;
      padding: 0 5px 0;
      font-family: Inter;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
    }

    img {
      display: block;
      width: 15px;
    }
  }

  ul {
    position: absolute;
    top: 100%;
    list-style-type: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
    cursor: pointer;

    li {
      min-width: 55px;
      padding: 5px;
      background-color: #FFFFFF;
      font-family: Inter;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;

      &:last-child {
        border-radius: 0 0 5px 5px;
      }

      &:hover {
        background-color: #E9EDD7;
      }
    }
  }

  &.active {
    button {
      img {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }
}


@media (max-width: 1600px) {
  .language-switcher {
    button {
      span {
        min-width: 50px;
        font-size: 18px;
        line-height: 27px;
      }
    }
  
    ul {
      li {
        min-width: 50px;
        font-size: 18px;
        line-height: 27px;
      }
    }
  } 
}

@media (max-width: 1440px) {
  .language-switcher {
    button {
      span {
        min-width: 45px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  
    ul {
      li {
        min-width: 45px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  } 
}

@media (max-width: 1280px) {
  .language-switcher {
    button {
      span {
        min-width: 40px;
        font-size: 14px;
        line-height: 21px;
      }
  
      img {
        width: 12px;
      }
    }
  
    ul {
      li {
        min-width: 40px;
        font-size: 14px;
        line-height: 21px;
      }
    }
  } 
}

@media (max-width: 1024px) {
  .language-switcher {
    button {
      span {
        min-width: 37.5px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  
    ul {
      li {
        min-width: 37.5px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  } 
}

@media (max-width: 834px) {
  .language-switcher {
    ul {
      li {
        padding: 3.5px 5px;
        background-color: #E9EDD7;
      }
    }
  } 
}