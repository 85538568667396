.CCIDocuments {
  background-color: #F7F8F4;
  
  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .cci-documents-content {
      padding: 80px 0;

      .heading-one {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .cci-documents-content {
        padding: 70px 0;
  
        .heading-one {
          font-size: 56px;
          line-height: 68px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .cci-documents-content {
        padding: 60px 0;
  
        .heading-one {
          font-size: 48px;
          line-height: 63px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .cci-documents-content {
        padding: 50px 0;
  
        .heading-one {
          font-size: 40px;
          line-height: 58px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .cci-documents-content {
        padding: 40px 0;
  
        .heading-one {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .cci-documents-content {
        padding: 30px 0;
  
        .heading-one {
          font-size: 25px;
          line-height: 36px;
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .cci-documents-content {
        padding: 20px 0;
  
        .heading-one {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}