.AdvertisingList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 90%;
}
.advertising-table { 
        width: 100%;
        border-collapse: collapse; /* Collapse borders between table cells */
    }
    
    /* Apply styles to table header */
    .advertising-table th {
        background-color: #f2f2f2; /* Light gray background color */
        padding: 10px;
        text-align: left; /* Align text to the left */
        border-bottom: 2px solid #ddd; /* Bottom border */
    }
    
    /* Apply styles to table rows */
    .advertising-table td {
        padding: 10px;
        border-bottom: 1px solid #ddd; /* Bottom border for each row */
    }
    
    /* Alternate row background color */
    .advertising-table tr:nth-child(even) {
        background-color: #f9f9f9; /* Lighter background color for even rows */
    }
    
    /* Apply specific styles to the cover column (assuming it contains images) */
    .advertising-table .cover {
        width: 100px; /* Set width for cover images */
    }
    
    /* Apply styles to table cell containing text */
    .advertising-table td.text {
        font-size: 14px; /* Adjust font size */
        color: #333; /* Text color */
    }
    
    /* Apply styles to table cell containing year of publishing */
    .add-table td.year {
        font-weight: bold; /* Bold font weight for emphasis */
    }