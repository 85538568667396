.service-list {
  padding-top: 65px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

@media (max-width: 1600px) {
  .service-list {
    padding-top: 60px;
  }
}

@media (max-width: 1440px) {
  .service-list {
    padding-top: 55px;
  }
}

@media (max-width: 1280px) {
  .service-list {
    padding-top: 50px;
  }
}

@media (max-width: 1024px) {
  .service-list {
    padding-top: 40px;
    gap: 25px;
  }
}

@media (max-width: 834px) {
  .service-list {
    padding-top: 30px;
  }
}

@media (max-width: 601px) {
  .service-list {
    padding-top: 15px;
    gap: 20px;
  }
}

@media (max-width: 390px) {
  .service-list {
    gap: 20px 15px;
  }
}