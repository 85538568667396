.log-in {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  background-color: rgba(61, 73, 16, 0.1);
  overflow-y: auto;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .modal-window {
      position: relative;
      padding: 30px 60px;
      border-radius: 15px;
      background-color: #FFFFFF;
      box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
  
      .heading-one {
        font-family: Inter;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        white-space: pre-wrap;
      }
  
      .description {
        padding-top: 30px;
        font-family: Inter;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        white-space: pre-wrap;
      }
  
      .close {
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: translate(40%, -40%);
        -moz-transform: translate(40%, -40%);
        -o-transform: translate(40%, -40%);
        -ms-transform: translate(40%, -40%);
        transform: translate(40%, -40%);
        border-radius: 15px;
        border: none;
        padding: 8px;
        background-color: #3D4910;
        cursor: pointer;
  
        img {
          display: block;
          width: 14px;
        }
      }
  
      .log-in-form {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
  
        .log-in-form-fields {
          .ordinary-input-wrapper {
            width: 100%;
          }
        }
  
        button[type="submit"] {
          align-self: center;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .log-in {
    .container {
      .modal-window {
        .heading-one {
          font-size: 37px;
          line-height: 45px;
        }
    
        .description {
          font-size: 17px;
          line-height: 24px;
        }
    
        .log-in-form {
          padding-top: 35px;
  
          button[type="submit"] {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .log-in {
    .container {
      .modal-window {
        padding: 25px 50px;
    
        .heading-one {
          font-size: 35px;
          line-height: 43px;
        }
    
        .description {
          padding-top: 25px;
          font-size: 16px;
          line-height: 23px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .log-in {
    .container {
      .modal-window {
        .heading-one {
          font-size: 33px;
          line-height: 41px;
        }
    
        .description {
          font-size: 15px;
          line-height: 22px;
        }
    
        .log-in-form {
          padding-top: 30px;
  
          button[type="submit"] {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .log-in {
    .container {
      .modal-window {
        padding: 20px 40px;
    
        .heading-one {
          font-size: 30px;
          line-height: 38px;
        }
    
        .description {
          padding-top: 20px;
          font-size: 14px;
          line-height: 20px;
        }
  
        .close {
          border-radius: 13px;
          padding: 7px;
    
          img {
            width: 12px;
          }
        }
    
        .log-in-form {
          padding-top: 25px;
    
          button[type="submit"] {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .log-in {
    .container {
      .modal-window {
        .heading-one {
          font-size: 27px;
          line-height: 35px;
        }
    
        .description {
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }
}

@media (max-width: 601px) {
  .log-in {
    .container {
      .modal-window {
        width: 100%;
        height: 100%;
        padding: 100px 60px 0;
        border-radius: 0;
    
        .heading-one {
          font-size: 24px;
          line-height: 32px;
        }
    
        .description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 18px;
        }
  
        .close {
          -webkit-transform: translate(-100%, 100%);
          -moz-transform: translate(-100%, 100%);
          -o-transform: translate(-100%, 100%);
          -ms-transform: translate(-100%, 100%);
          transform: translate(-100%, 100%);
          border-radius: 15px;
          padding: 8px;
    
          img {
            width: 14px;
          }
        }
    
        .log-in-form {
          padding-top: 20px;
    
          button[type="submit"] {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .log-in {
    .container {
      .modal-window {
        padding: 100px 40px 0;
      }
    }
  }
}

@media (max-width: 390px) {
  .log-in {
    .container {
      .modal-window {
        padding: 100px 25px 0;
      }
    }
  }
}