.App {
  .main {
    .ErrorPage {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F7F8F4;

      .wrapper {
        margin: auto;
        width: 80%;
        max-width: 1400px;

        .error-page-content {
          padding: 80px 0;

          img {
            display: block;
            margin: auto;
            width: 850px;
            padding-bottom: 50px;
          }

          .primary-paragraph {
            padding-bottom: 40px;
            font-family: Inter;
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            text-align: center;
            text-transform: uppercase;
          }

          .secondary-paragraph {
            padding-bottom: 60px;
            font-family: Inter;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
          }

          button {
            display: block;
            margin: auto;
          }
        }
      }

      @media (max-width: 1600px) {
        .wrapper {
          .error-page-content {
            padding: 70px 0;
  
            img {
              width: 750px;
              padding-bottom: 45px;
            }
  
            .primary-paragraph {
              padding-bottom: 35px;
              font-size: 36px;
              line-height: 44px;
            }
  
            .secondary-paragraph {
              padding-bottom: 50px;
              font-size: 22px;
              line-height: 29px;
            }
          }
        }
      }

      @media (max-width: 1440px) {
        .wrapper {
          .error-page-content {
            padding: 60px 0;
  
            img {
              width: 700px;
              padding-bottom: 40px;
            }
  
            .primary-paragraph {
              padding-bottom: 30px;
              font-size: 32px;
              line-height: 40px;
            }
  
            .secondary-paragraph {
              padding-bottom: 45px;
              font-size: 21px;
              line-height: 27px;
            }
          }
        }
      }
      
      @media (max-width: 1280px) {
        .wrapper {
          .error-page-content {
            padding: 50px 0;
  
            img {
              width: 650px;
              padding-bottom: 35px;
            }
  
            .primary-paragraph {
              padding-bottom: 25px;
              font-size: 30px;
              line-height: 38px;
              white-space: pre-wrap;
            }
  
            .secondary-paragraph {
              padding-bottom: 40px;
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }

      @media (max-width: 1024px) {
        .wrapper {
          .error-page-content {
            padding: 40px 0;
  
            img {
              width: 550px;
              padding-bottom: 30px;
            }
  
            .primary-paragraph {
              padding-bottom: 20px;
              font-size: 27px;
              line-height: 34px;
            }
  
            .secondary-paragraph {
              padding-bottom: 30px;
              font-size: 17px;
              line-height: 21px;
            }
          }
        }
      }

      @media (max-width: 834px) {
        .wrapper {
          width: 90%;
          
          .error-page-content {
            padding: 30px 0;
  
            img {
              width: 450px;
              padding-bottom: 25px;
            }
  
            .primary-paragraph {
              font-size: 23px;
              line-height: 29px;
            }
  
            .secondary-paragraph {
              padding-bottom: 25px;
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }

      @media (max-width: 601px) {
        .wrapper {
          .error-page-content {
            padding: 20px 0;
  
            img {
              width: 350px;
              padding-bottom: 20px;
            }
  
            .primary-paragraph {
              padding-bottom: 15px;
              font-size: 21px;
              line-height: 26px;
            }
  
            .secondary-paragraph {
              padding-bottom: 20px;
              font-size: 13px;
              line-height: 17px;
              white-space: pre-wrap;
            }
          }
        }
      }

      @media (max-width: 430px) {
        .wrapper {
          .error-page-content {
            img {
              width: 250px;
            }
  
            .primary-paragraph {
              font-size: 18px;
              line-height: 22px;
            }
  
            .secondary-paragraph {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}