.image-gallery-content {
  .image-gallery-slide {
    .image-gallery-image {
      object-fit: cover;
    }
  }

  .image-gallery-bullets {
    .image-gallery-bullet {
      &:hover {
        border: 1px solid #3D4910;
        background-color: #3D4910;
      }
    }
  }
}

@media (max-width: 1024px) {
  .image-gallery-content {
    .image-gallery-bullets {
      .image-gallery-bullet {
        padding: 4px;
      }
    }
  }
}

@media (max-width: 601px) {
  .image-gallery-content {
    .image-gallery-bullets {
      .image-gallery-bullet {
        padding: 3px;
      }
    }
  }
}