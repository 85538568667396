.community-indicator-list {
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1600px) {
  .community-indicator-list {
    padding: 65px 0 70px;
  }
}

@media (max-width: 1440px) {
  .community-indicator-list {
    padding: 50px 0 60px;
  }
}

@media (max-width: 1280px) {
  .community-indicator-list {
    padding: 35px 0 50px;
  }
}

@media (max-width: 1024px) {
  .community-indicator-list {
    padding: 20px 0 40px;
  }
}

@media (max-width: 834px) {
  .community-indicator-list {
    padding: 20px 0 30px;
  }
}

@media (max-width: 601px) {
  .community-indicator-list {
    padding: 15px 0 20px;
  }
}