.ArticlePage {
  background-color: #F7F8F4;
  
  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .article-page-content {
      padding: 80px 0;

      .previous-page-link {
        margin-bottom: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-family: Inter;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
      }

      .heading-one,
      .heading-two {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .article-cover {
        display: block;
        margin-top: 80px;
        width: 100%;
        border-radius: 20px;
        box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
      }

      .article-details {
        display: flex;
        align-items: flex-start;
        column-gap: 5%;
        padding-top: 80px;

        .article-info {
          width: 25%;

          .info-item {
            display: flex;
            align-items: center;
            column-gap: 15px;
            padding-bottom: 15px;

            .info-icon {
              display: block;
              width: 20px;
            }

            .paragraph {
              font-family: Inter;
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
            }

            &:last-of-type {
              padding-bottom: 0;
            }
          }
        }

        .article-body {
          width: 60%;
          font-family: Inter;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          text-align: justify;

          div,
          p,
          span,
          a,
          ul > li,
          ol > li {
            background-color: transparent !important;
            font-family: Inter !important;
            font-size: 18px !important;
            line-height: 26px !important;
            text-align: justify !important;
            color: #3D4910 !important;
            word-wrap: normal !important;
          }

          div,
          p {
            margin: 0 !important;
            padding: 30px 0 0 !important;
            white-space: normal !important;

            &:first-child {
              padding-top: 0 !important;
            }
          }

          span {
            margin: 0 !important;
            padding: 0 !important;
          }

          a {
            margin: 0 !important;
            padding: 0 !important;
            text-decoration: none;

            &:hover {
              text-decoration: underline !important;
            }
          }

          ul > li,
          ol > li {
            margin: 0 0 0 20px !important;
            padding: 0 !important;
            white-space: normal !important;
          }
        }
      }

      .single-photo {
        display: block;
        margin-top: 80px;
        width: 100%;
        border-radius: 20px;
      }

      .additional-photos {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 80px;

        .additional-photo {
          display: block;
          width: 48.5%;
          border-radius: 15px;
        }
      }

      .article-slider-block {
        padding-top: 80px;

        .slick-arrow {
          top: -20px;
          width: 40px;
          height: 40px;
          -webkit-transform: translateY(-100%);
          -moz-transform: translateY(-100%);
          -o-transform: translateY(-100%);
          -ms-transform: translateY(-100%);
          transform: translateY(-100%);
        }

        .slick-prev {
          left: -webkit-calc(100% - 80px);
          left: -moz-calc(100% - 80px);
          left: -o-calc(100% - 80px);
          left: -ms-calc(100% - 80px);
          left: calc(100% - 80px);
        }

        .slick-next {
          right: 0;
        }

        .slide-block {
          .additional-slide {
            border-radius: 15px;
            width: 95%;
          }
        }
      }

      .heading-two_form {
        padding-top: 80px;
      }

      .form-description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }

      .notification {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #C85252;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .article-page-content {
        padding: 70px 0;

        .previous-page-link {
          margin-bottom: 45px;
          font-size: 19px;
          line-height: 28px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 56px;
          line-height: 68px;
        }
  
        .article-cover {
          margin-top: 70px;
        }
  
        .article-details {
          padding-top: 70px;
  
          .article-info {
            width: 28%;
  
            .info-item {
              .info-icon {
                width: 18px;
              }
  
              .paragraph {
                line-height: 24px;
              }
            }
          }
  
          .article-body {
            font-size: 17px;
            line-height: 24px;
  
            div,
            p,
            span,
            a,
            ul > li,
            ol > li {
              font-size: 17px !important;
              line-height: 24px !important;
            }

            div,
            p {
              padding: 25px 0 0 !important;
            }
          }
        }
  
        .single-photo {
          margin-top: 70px;
        }
  
        .additional-photos {
          margin-top: 70px;
        }
  
        .article-slider-block {
          padding-top: 70px;
  
          .slick-arrow {
            top: -15px;
          }
        }

        .heading-two_form {
          padding-top: 70px;
        }
  
        .form-description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        .notification {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .article-page-content {
        padding: 60px 0;

        .previous-page-link {
          margin-bottom: 40px;
          font-size: 18px;
          line-height: 26px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 48px;
          line-height: 63px;
        }
  
        .article-cover {
          margin-top: 60px;
          border-radius: 15px;
        }
  
        .article-details {
          padding-top: 60px;
  
          .article-info {
            width: 30%;
  
            .info-item {
              .paragraph {
                font-size: 16px;
              }
            }
          }
  
          .article-body {
            font-size: 16px;
            line-height: 23px;
  
            div,
            p,
            span,
            a,
            ul > li,
            ol > li {
              font-size: 16px !important;
              line-height: 23px !important;
            }
          }
        }
  
        .single-photo {
          margin-top: 60px;
          border-radius: 15px;
        }
  
        .additional-photos {
          margin-top: 60px;
        }
  
        .article-slider-block {
          padding-top: 60px;
  
          .slick-arrow {
            width: 30px;
            height: 30px;
          }
  
          .slick-prev {
            left: -webkit-calc(100% - 60px);
            left: -moz-calc(100% - 60px);
            left: -o-calc(100% - 60px);
            left: -ms-calc(100% - 60px);
            left: calc(100% - 60px);
          }
        }

        .heading-two_form {
          padding-top: 60px;
        }
  
        .form-description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        .notification {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .article-page-content {
        padding: 50px 0;

        .previous-page-link {
          margin-bottom: 35px;
          font-size: 17px;
          line-height: 24px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 40px;
          line-height: 58px;
        }
  
        .article-cover {
          margin-top: 50px;
        }
  
        .article-details {
          padding-top: 50px;
  
          .article-info {
            width: 32%;
  
            .info-item {
              .info-icon {
                width: 16px;
              }
  
              .paragraph {
                line-height: 22px;
              }
            }
          }
  
          .article-body {
            font-size: 15px;
            line-height: 22px;
  
            div,
            p,
            span,
            a,
            ul > li,
            ol > li {
              font-size: 15px !important;
              line-height: 22px !important;
            }

            div,
            p {
              padding: 20px 0 0 !important;
            }
          }
        }
  
        .single-photo {
          margin-top: 50px;
        }
  
        .additional-photos {
          margin-top: 50px;
        }
  
        .article-slider-block {
          padding-top: 50px;
  
          .slick-arrow {
            top: -10px;
          }
        }

        .heading-two_form {
          padding-top: 50px;
        }
  
        .form-description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        .notification {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .article-page-content {
        padding: 40px 0;

        .previous-page-link {
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 22px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 32px;
          line-height: 48px;
        }
  
        .article-cover {
          margin-top: 40px;
          border-radius: 10px;
        }
  
        .article-details {
          padding-top: 40px;
  
          .article-info {
            width: 35%;
  
            .info-item {
              column-gap: 10px;
              padding-bottom: 10px;
  
              .info-icon {
                width: 14px;
              }
  
              .paragraph {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
  
          .article-body {
            font-size: 14px;
            line-height: 20px;
  
            div,
            p,
            span,
            a,
            ul > li,
            ol > li {
              font-size: 14px !important;
              line-height: 20px !important;
            }
  
            div,
            p {
              padding: 15px 0 0 !important;
            }
          }
        }
  
        .single-photo {
          margin-top: 40px;
          border-radius: 10px;
        }
  
        .additional-photos {
          margin-top: 40px;
  
          .additional-photo {
            border-radius: 10px;
          }
        }
  
        .article-slider-block {
          padding-top: 40px;
  
          .slick-arrow {
            width: 20px;
            height: 20px;
          }
  
          .slick-prev {
            left: -webkit-calc(100% - 40px);
            left: -moz-calc(100% - 40px);
            left: -o-calc(100% - 40px);
            left: -ms-calc(100% - 40px);
            left: calc(100% - 40px);
          }
  
          .slide-block {
            .additional-slide {
              border-radius: 10px;
            }
          }
        }

        .heading-two_form {
          padding-top: 40px;
        }
  
        .form-description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        .notification {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .article-page-content {
        padding: 30px 0;

        .previous-page-link {
          margin-bottom: 25px;
          font-size: 14px;
          line-height: 18px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 25px;
          line-height: 36px;
        }
  
        .article-cover {
          margin-top: 30px;
        }
  
        .article-details {
          display: block;
          padding-top: 30px;
  
          .article-info {
            width: 100%;
  
            .info-item {
              .paragraph {
                font-size: 13px;
                line-height: 18px;
              }
            }
          }
  
          .article-body {
            width: 100%;
            padding-top: 30px;
            font-size: 13px;
            line-height: 19px;
  
            div,
            p,
            span,
            a,
            ul > li,
            ol > li {
              font-size: 13px !important;
              line-height: 19px !important;
            }
          }
        }
  
        .single-photo {
          margin-top: 30px;
        }
  
        .additional-photos {
          margin-top: 30px;
        }
  
        .article-slider-block {
          padding-top: 30px;

          .slick-dots {
            display: flex !important;
            justify-content: center;
            bottom: -25px;
          }
        }

        .heading-two_form {
          padding-top: 30px;
        }
  
        .form-description {
          font-size: 14px;
          line-height: 20px;
        }
  
        .notification {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .article-page-content {
        padding: 20px 0;

        .previous-page-link {
          margin-bottom: 20px;
          font-size: 12px;
          line-height: 14px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 18px;
          line-height: 24px;
        }
  
        .article-cover {
          margin-top: 20px;
        }
  
        .article-details {
          padding-top: 20px;
  
          .article-info {
            .info-item {
              column-gap: 7.5px;
              padding-bottom: 7.5px;
  
              .info-icon {
                width: 12px;
              }
  
              .paragraph {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
  
          .article-body {
            padding-top: 20px;
            font-size: 12px;
            line-height: 18px;
  
            div,
            p,
            span,
            a,
            ul > li,
            ol > li {
              font-size: 12px !important;
              line-height: 18px !important;
            }
  
            div,
            p {
              padding: 10px 0 0 !important;
            }
          }
        }
  
        .single-photo {
          margin-top: 20px;
        }
  
        .additional-photos {
          margin-top: 20px;
  
          .additional-photo {
            border-radius: 5px;
          }
        }
  
        .article-slider-block {
          padding-top: 20px;
  
          .slide-block {
            .additional-slide {
              border-radius: 5px;
            }
          }

          .slick-dots {
            bottom: -20px;
          }
        }

        .heading-two_form {
          padding-top: 20px;
        }
  
        .form-description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        .notification {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}