.manager-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px 30px;
  padding-top: 80px;
}

.managers-not-found {
  padding-top: 40px;
  font-family: Inter;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

@media (max-width: 1600px) {
  .manager-list {
    gap: 70px 25px;
    padding-top: 65px;
  }

  .managers-not-found {
    padding-top: 35px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 1440px) {
  .manager-list {
    gap: 60px 25px;
    padding-top: 50px;
  }

  .managers-not-found {
    padding-top: 30px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1280px) {
  .manager-list {
    gap: 50px 25px;
    padding-top: 35px;
  }

  .managers-not-found {
    padding-top: 25px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .manager-list {
    gap: 40px 25px;
    padding-top: 20px;
  }

  .managers-not-found {
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 834px) {
  .managers-not-found {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 601px) {
  .manager-list {
    gap: 30px 20px;
    padding-top: 15px;
  }

  .managers-not-found {
    padding-top: 15px;
    font-size: 12px;
    line-height: 16px;
  }
}