.EventInfo {
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    p:first-child {width: 50px}
    p:nth-child(2) {width: 120px}
    p:nth-child(3) {width: 250px}
    p:nth-child(4) {width: 100px}
    p:nth-child(5) {width: 120px}
    p:nth-child(6) {width: 120px}
    p:nth-child(7) {width: 150px}
    p {
       
    }
}