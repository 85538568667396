.initiative-form {
  display: flex;
  flex-direction: column;
  padding-top: 65px;

  .initiative-form-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 50px;

    .ordinary-input-wrapper {
      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(3) {
        width: 48%;
      }
    }

    .phone-input-wrapper {
      width: 48%;
    }

    .textarea-wrapper {
      width: 100%;
    }
  }

  .attach {
    align-self: flex-start;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border: none;
    padding-bottom: 5px;
    background-color: transparent;
    cursor: pointer;

    img {
      display: block;
      width: 12.5px;
    }

    span {
      font-family: Inter;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }
  }

  button[type="submit"] {
    align-self: center;
    margin-top: 50px;
  }
}

@media (max-width: 1600px) {
  .initiative-form {
    padding-top: 55px;
  
    .initiative-form-fields {
      row-gap: 40px;
    }

    .attach {
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  
    button[type="submit"] {
      margin-top: 40px;
    }
  }
}

@media (max-width: 1440px) {
  .initiative-form {
    padding-top: 45px;
  
    .initiative-form-fields {
      row-gap: 35px;
    }

    .attach {
      span {
        line-height: 22px;
      }
    }
  
    button[type="submit"] {
      margin-top: 35px;
    }
  }
}

@media (max-width: 1280px) {
  .initiative-form {
    padding-top: 35px;
  
    .initiative-form-fields {
      row-gap: 30px;
    }

    .attach {
      column-gap: 7.5px;

      img {
        width: 10px;
      }
  
      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  
    button[type="submit"] {
      margin-top: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .initiative-form {
    padding-top: 20px;
  
    .initiative-form-fields {
      row-gap: 20px;
    }

    .attach {
      span {
        font-size: 12px;
        line-height: 18px;
      }
    }
  
    button[type="submit"] {
      margin-top: 20px;
    }
  }
}

@media (max-width: 834px) {
  .initiative-form {
    .initiative-form-fields {
      row-gap: 10px;
    }
  
    button[type="submit"] {
      margin-top: 10px;
    }
  }
}

@media (max-width: 601px) {
  .initiative-form {
    padding-top: 15px;
  
    .initiative-form-fields {
      row-gap: 0;
      
      .ordinary-input-wrapper {
        &:nth-child(3) {
          width: 100%;
        }
      }
  
      .phone-input-wrapper {
        width: 100%;
      }
    }

    .attach {
      column-gap: 5px;

      img {
        width: 7.5px;
      }
  
      span {
        font-size: 10px;
        line-height: 16px;
      }
    }
  
    button[type="submit"] {
      margin-top: 0;
    }
  }
}