.activity-list {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  padding-top: 80px;

  &.loading {
    flex-direction: row;
    justify-content: center;
  }
}

.activities-not-found {
  padding-top: 40px;
  font-family: Inter;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

@media (max-width: 1600px) {
  .activity-list {
    row-gap: 70px;
    padding-top: 65px;
  }
  
  .activities-not-found {
    padding-top: 35px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 1440px) {
  .activity-list {
    row-gap: 60px;
    padding-top: 50px;
  }
  
  .activities-not-found {
    padding-top: 30px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1280px) {
  .activity-list {
    row-gap: 50px;
    padding-top: 35px;
  }
  
  .activities-not-found {
    padding-top: 25px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .activity-list {
    row-gap: 40px;
    padding-top: 20px;
  }
  
  .activities-not-found {
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 834px) {
  .activity-list {
    row-gap: 35px;
  }
  
  .activities-not-found {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 601px) {
  .activity-list {
    row-gap: 30px;
    padding-top: 15px;
  }
  
  .activities-not-found {
    padding-top: 15px;
    font-size: 12px;
    line-height: 16px;
  }
}