.Activities {
  position: relative;
  background-color: #F7F8F4;

  .anchor {
    position: absolute;
  }
  
  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .activities-content {
      display: flex;
      flex-direction: column;
      padding: 80px 0;

      .heading-two {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }

      .activities-slider-block {
        padding-top: 80px;

        .slick-arrow {
          top: -20px;
          width: 40px;
          height: 40px;
          -webkit-transform: translateY(-100%);
          -moz-transform: translateY(-100%);
          -o-transform: translateY(-100%);
          -ms-transform: translateY(-100%);
          transform: translateY(-100%);
        }

        .slick-prev {
          left: -webkit-calc(100% - 80px);
          left: -moz-calc(100% - 80px);
          left: -o-calc(100% - 80px);
          left: -ms-calc(100% - 80px);
          left: calc(100% - 80px);
        }

        .slick-next {
          right: 0;
        }

        .slick-track {
          .slick-slide {
            a {
              display: block !important;
              cursor: default;
  
              .activity-item {
                position: relative;
                display: flex;
                align-items: center;
                width: 95%;
                border-radius: 15px;
                overflow: hidden;
                cursor: pointer;
    
                .image-dimming {
                  position: absolute;
                  z-index: 1;
                  border-radius: 15px;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.5);
                }
                
                .activity-image {
                  width: 100%;
                  -webkit-transition: transform 1s;
                  -moz-transition: transform 1s;
                  -o-transition: transform 1s;
                  -ms-transition: transform 1s;
                  transition: transform 1s;
                }

                &:hover {
                  .activity-image {
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    transform: scale(1.1);
                  }
                }
    
                .activity-title {
                  position: absolute;
                  z-index: 1;
                  left: 0;
                  top: 20%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  row-gap: 30px;
                  width: 100%;
                  -webkit-transition: top 1s;
                  -moz-transition: top 1s;
                  -o-transition: top 1s;
                  -ms-transition: top 1s;
                  transition: top 1s;
    
                  .cover-icon {
                    width: 40px;
                  }
        
                  .heading-three {
                    padding: 0 40px;
                    font-family: Inter;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: uppercase;
                    text-align: center;
                    color: #FFFFFF;
                  }
                }

                &:hover {
                  .activity-title {
                    top: 10%;
                  }
                }

                .event-date {
                  position: absolute;
                  z-index: 1;
                  bottom: 0;
                  -webkit-transform: translateY(100%);
                  -moz-transform: translateY(100%);
                  -o-transform: translateY(100%);
                  -ms-transform: translateY(100%);
                  transform: translateY(100%);
                  width: 100%;
                  padding: 0 40px;
                  font-family: Inter;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 24px;
                  text-align: center;
                  color: #FFFFFF;
                  -webkit-transition-duration: 1s;
                  -moz-transition-duration: 1s;
                  -o-transition-duration: 1s;
                  -ms-transition-duration: 1s;
                  transition-duration: 1s;
                }

                &:hover {
                  .event-date {
                    bottom: 10%;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -o-transform: translateY(0);
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                  }
                }
              }
            }
          }
        }
      }

      .notification {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #C85252;
      }
      
      .activities-not-found {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }

      button {
        align-self: center;
        margin-top: 80px;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .activities-content {
        padding: 70px 0;
  
        .heading-two {
          font-size: 56px;
          line-height: 68px;
        }
    
        .description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        .activities-slider-block {
          padding-top: 70px;
  
          .slick-arrow {
            top: -15px;
          }
  
          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  .activity-title {
                    row-gap: 25px;

                    .heading-three {
                      font-size: 18px;
                      line-height: 22px;
                    }
                  }

                  .event-date {
                    font-size: 18px;
                    line-height: 22px;
                  }

                  &:hover {
                    .event-date {
                      bottom: 8%;
                    }
                  }
                }
              }
            }
          }
        }
  
        .notification {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
        
        .activities-not-found {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        button {
          margin-top: 70px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .activities-content {
        padding: 60px 0;
  
        .heading-two {
          font-size: 48px;
          line-height: 63px;
        }
    
        .description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        .activities-slider-block {
          padding-top: 60px;
  
          .slick-arrow {
            width: 30px;
            height: 30px;
          }
  
          .slick-prev {
            left: -webkit-calc(100% - 60px);
            left: -moz-calc(100% - 60px);
            left: -o-calc(100% - 60px);
            left: -ms-calc(100% - 60px);
            left: calc(100% - 60px);
          }
  
          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  .activity-title {
                    row-gap: 30px;

                    .heading-three {
                      padding: 0 30px;
                      font-size: 20px;
                      line-height: 24px;
                    }
                  }

                  .event-date {
                    padding: 0 30px;
                    font-size: 20px;
                    line-height: 24px;
                  }

                  &:hover {
                    .event-date {
                      bottom: 10%;
                    }
                  }
                }
              }
            }
          }
        }
  
        .notification {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
        
        .activities-not-found {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        button {
          margin-top: 60px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .activities-content {
        padding: 50px 0;
  
        .heading-two {
          font-size: 40px;
          line-height: 58px;
        }
    
        .description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        .activities-slider-block {
          padding-top: 50px;
  
          .slick-arrow {
            top: -10px;
          }

          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  &:hover {
                    .event-date {
                      bottom: 8%;
                    }
                  }
                }
              }
            }
          }
        }
  
        .notification {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
        
        .activities-not-found {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        button {
          margin-top: 50px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .activities-content {
        padding: 40px 0;
  
        .heading-two {
          font-size: 32px;
          line-height: 48px;
        }
    
        .description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        .activities-slider-block {
          padding-top: 40px;
  
          .slick-arrow {
            width: 20px;
            height: 20px;
          }
  
          .slick-prev {
            left: -webkit-calc(100% - 40px);
            left: -moz-calc(100% - 40px);
            left: -o-calc(100% - 40px);
            left: -ms-calc(100% - 40px);
            left: calc(100% - 40px);
          }
  
          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  border-radius: 10px;
      
                  .image-dimming {
                    border-radius: 10px;
                  }
      
                  .activity-title {
                    row-gap: 20px;
      
                    .cover-icon {
                      width: 30px;
                    }
          
                    .heading-three {
                      padding: 0 20px;
                      font-size: 18px;
                      line-height: 22px;
                    }
                  }

                  .event-date {
                    padding: 0 20px;
                    font-size: 18px;
                    line-height: 22px;
                  }

                  &:hover {
                    .event-date {
                      bottom: 5%;
                    }
                  }
                }
              }
            }
          }
        }
  
        .notification {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
        
        .activities-not-found {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        button {
          margin-top: 40px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .activities-content {
        padding: 30px 0;
  
        .heading-two {
          font-size: 25px;
          line-height: 36px;
        }
    
        .description {
          font-size: 14px;
          line-height: 20px;
        }
  
        .activities-slider-block {
          padding-top: 30px;
  
          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  &:hover {
                    .activity-image {
                      -webkit-transform: scale(1);
                      -moz-transform: scale(1);
                      -o-transform: scale(1);
                      -ms-transform: scale(1);
                      transform: scale(1);
                    }
                  }

                  .activity-title {
                    top: 10%;
          
                    .heading-three {
                      font-size: 16px;
                      line-height: 20px;
                    }
                  }

                  .event-date {
                    bottom: 10%;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -o-transform: translateY(0);
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                    font-size: 16px;
                    line-height: 20px;
                  }

                  &:hover {
                    .event-date {
                      bottom: 10%;
                    }
                  }
                }
              }
            }
          }

          .slick-dots {
            display: flex !important;
            justify-content: center;
            bottom: -30px;
          }
        }
  
        .notification {
          font-size: 14px;
          line-height: 20px;
        }
        
        .activities-not-found {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      .activities-content {
        .activities-slider-block {
          padding-top: 30px;
  
          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  .activity-title {
                    top: 7.5%;

                    .heading-three {
                      font-size: 14px;
                      line-height: 16px;
                    }
                  }

                  &:hover {
                    .activity-title {
                      top: 7.5%;
                    }
                  }

                  .event-date {
                    bottom: 7.5%;
                    font-size: 14px;
                    line-height: 16px;
                  }

                  &:hover {
                    .event-date {
                      bottom: 7.5%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .activities-content {
        padding: 20px 0;
  
        .heading-two {
          font-size: 18px;
          line-height: 24px;
        }
    
        .description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        .activities-slider-block {
          padding-top: 20px;
  
          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  .activity-title {
                    top: 10%;

                    .heading-three {
                      padding: 0 40px;
                      font-size: 18px;
                      line-height: 22px;
                    }
                  }

                  &:hover {
                    .activity-title {
                      top: 10%;
                    }
                  }

                  .event-date {
                    bottom: 10%;
                    padding: 0 40px;
                    font-size: 18px;
                    line-height: 22px;
                  }

                  &:hover {
                    .event-date {
                      bottom: 10%;
                    }
                  }
                }
              }
            }
          }

          .slick-dots {
            bottom: -25px;
          }
        }
  
        .notification {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
        
        .activities-not-found {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        button {
          margin-top: 30px;
        }
      }
    }
  }

  @media (max-width: 430px) {
    .wrapper {
      .activities-content {
        .activities-slider-block {
          padding-top: 30px;
  
          .slick-track {
            .slick-slide {
              a {
                .activity-item {
                  .activity-title {
                    top: 7.5%;

                    .heading-three {
                      padding: 0 30px;
                      font-size: 16px;
                      line-height: 20px;
                    }
                  }

                  &:hover {
                    .activity-title {
                      top: 7.5%;
                    }
                  }

                  .event-date {
                    bottom: 7.5%;
                    padding: 0 30px;
                    font-size: 16px;
                    line-height: 20px;
                  }

                  &:hover {
                    .event-date {
                      bottom: 7.5%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}