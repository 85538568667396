.company-list__item {
  width: -webkit-calc((100% - 30px)/2);
  width: -moz-calc((100% - 30px)/2);
  width: -o-calc((100% - 30px)/2);
  width: -ms-calc((100% - 30px)/2);
  width: calc((100% - 30px)/2);
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company-list__cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 46%;

    .company-logo {
      display: block;
      width: 100px;
    }

    .heading-two {
      padding-top: 30px;
      font-family: Inter;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: uppercase;
      word-break: break-word;
    }
  }

  .company-list__basic-info {
    width: 50%;

    .paragraph {
      font-family: Inter;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      word-break: break-word;

      &:first-child {
        padding-bottom: 30px;
      }
    }

    .basic-info-item {
      display: flex;
      align-items: center;
      column-gap: 15px;
      padding-top: 15px;

      .info-icon {
        display: block;
        width: 20px;
      }

      &:first-of-type {
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 1600px) {
  .company-list__item {
    width: -webkit-calc((100% - 27px)/2);
    width: -moz-calc((100% - 27px)/2);
    width: -o-calc((100% - 27px)/2);
    width: -ms-calc((100% - 27px)/2);
    width: calc((100% - 27px)/2);
    padding: 22px 27px;
  
    .company-list__cover {
      width: 44%;
  
      .company-logo {
        width: 90px;
      }
  
      .heading-two {
        font-size: 15px;
        line-height: 22px;
      }
    }
  
    .company-list__basic-info {
      width: 52%;
  
      .paragraph {
        font-size: 16px;
        line-height: 23px;
  
        &:first-child {
          padding-bottom: 27px;
        }
      }
  
      .basic-info-item {
        .info-icon {
          width: 18px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .company-list__item {
    width: -webkit-calc((100% - 25px)/2);
    width: -moz-calc((100% - 25px)/2);
    width: -o-calc((100% - 25px)/2);
    width: -ms-calc((100% - 25px)/2);
    width: calc((100% - 25px)/2);
    padding: 20px 25px;
  
    .company-list__cover {
      width: 43%;
  
      .company-logo {
        width: 82.5px;
      }
  
      .heading-two {
        padding-top: 25px;
      }
    }
  
    .company-list__basic-info {
      width: 53%;
  
      .paragraph {
        line-height: 21px;
  
        &:first-child {
          padding-bottom: 25px;
        }
      }
  
      .basic-info-item {
        column-gap: 12.5px;
        padding-top: 12.5px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .company-list__item {
    width: -webkit-calc((100% - 23px)/2);
    width: -moz-calc((100% - 23px)/2);
    width: -o-calc((100% - 23px)/2);
    width: -ms-calc((100% - 23px)/2);
    width: calc((100% - 23px)/2);
    padding: 18px 23px;
  
    .company-list__cover {
      width: 42%;
  
      .company-logo {
        width: 75px;
      }
  
      .heading-two {
        font-size: 14px;
        line-height: 20px;
      }
    }
  
    .company-list__basic-info {
      width: 54%;
  
      .paragraph {
        font-size: 14px;
        line-height: 19px;
  
        &:first-child {
          padding-bottom: 23px;
        }
      }
  
      .basic-info-item {
        .info-icon {
          width: 16px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .company-list__item {
    width: -webkit-calc((100% - 20px)/2);
    width: -moz-calc((100% - 20px)/2);
    width: -o-calc((100% - 20px)/2);
    width: -ms-calc((100% - 20px)/2);
    width: calc((100% - 20px)/2);
    border-radius: 10px;
    padding: 15px 20px;
  
    .company-list__cover {
      width: 40%;
  
      .company-logo {
        width: 65px;
      }
  
      .heading-two {
        padding-top: 20px;
        font-size: 13px;
        line-height: 18px;
      }
    }
  
    .company-list__basic-info {
      width: 56%;
  
      .paragraph {
        font-size: 12px;
        line-height: 16px;
  
        &:first-child {
          padding-bottom: 20px;
        }
      }
  
      .basic-info-item {
        column-gap: 10px;
        padding-top: 10px;
  
        .info-icon {
          width: 14px;
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .company-list__item {
    padding: 20px 40px;
    flex-direction: column;

    .company-list__cover {
      width: 100%;
      
      .heading-two {
        font-size: 14px;
        line-height: 20px;
      }
    }
  
    .company-list__basic-info {
      width: 100%;
  
      .paragraph {
        font-size: 14px;
        line-height: 19px;

        &:first-child {
          padding: 17.5px 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .company-list__item {
    padding: 20px 30px;
  }
}

@media (max-width: 601px) {
  .company-list__item {
    padding: 15px 20px;
  
    .company-list__cover {
      .company-logo {
        width: 60px;
      }
  
      .heading-two {
        padding-top: 15px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  
    .company-list__basic-info {
      .paragraph {
        font-size: 10px;
        line-height: 14px;
  
        &:first-child {
          padding: 15px 0;
        }
      }
  
      .basic-info-item {
        column-gap: 7.5px;
        padding-top: 7.5px;
  
        .info-icon {
          width: 12px;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .company-list__item {
    width: 100%;
    flex-direction: row;
  
    .company-list__cover {
      width: 43%;
  
      .company-logo {
        width: 55px;
      }
    }
  
    .company-list__basic-info {
      width: 53%;

      .paragraph {
        &:first-child {
          padding: 0 0 15px;
        }
      }
    }
  }
}