.admin_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #3D4910;
  border-radius: 30px;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  width: 150px;
  height: 35px;
  margin: 10px;
  cursor: pointer;
};

.admin_button_primary {
  background-color: #3D4910;

  a {
    display: block;
    padding: 15px 30px;
    text-decoration: none;
    color: #FFFFFF;
  }

  &:hover {
    background-color: transparent;

    a {
      color: #3D4910;
    }
  }
}

.admin_button_secondary {
  background-color: transparent;
  margin-left: 10px;

  a {
    display: block;
    padding: 15px 30px;
    text-decoration: none;
    color: #3D4910;
  }

  &:hover {
    background-color: #3D4910;

    a {
      color: #FFFFFF;
    }
  }
}