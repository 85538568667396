.Footer {
  background-color: #E9EDD7;

  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .footer-content {
      display: flex;
      justify-content: space-between;
      padding: 65px 0 50px;

      .copyright {
        width: 11%;
        font-family: Inter;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }

      .address {
        width: 20%;
        font-family: Inter;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        white-space: pre-wrap;
      }

      .contact-list {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 15%;

        .contact-list__item {
          display: flex;
          align-items: center;
          column-gap: 20px;

          .info-icon {
            display: block;
            width: 25px;
          }

          a {
            font-family: Inter;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-decoration: none;
          }
        }
      }

      .social-networks {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 7%;

        a {
          img {
            display: block;
            width: 25px;
          }
        }
      }

      .disclaimer {
        width: 17%;
        font-family: Inter;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
      }

      .donors {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 23%;

        a {
          &:nth-child(1) {
            img {
              display: block;
              width: 80px;
            }
          }

          &:nth-child(2) {
            img {
              display: block;
              width: 200px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .footer-content {
        padding: 55px 0 45px;
  
        .copyright {
          font-size: 16px;
          line-height: 24px;
        }
  
        .address {
          font-size: 16px;
          line-height: 24px;
        }
  
        .contact-list {
          width: 14%;

          .contact-list__item {
            .info-icon {
              width: 21px;
            }
  
            a {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
  
        .social-networks {
          a {
            img {
              width: 21px;
            }
          }
        }

        .disclaimer {
          width: 19%;
          line-height: 15px;
        }
  
        .donors {
          a {
            &:nth-child(1) {
              img {
                width: 70px;
              }
            }
  
            &:nth-child(2) {
              img {
                width: 175px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .footer-content {
        padding: 47.5px 0 40px;
  
        .copyright {
          width: 12%;
          line-height: 22px;
        }
  
        .address {
          width: 21%;
          line-height: 22px;
        }
  
        .contact-list {
          row-gap: 15px;
          width: 15%;
  
          .contact-list__item {
            column-gap: 15px;
  
            .info-icon {
              width: 19px;
            }
  
            a {
              line-height: 22px;
            }
          }
        }
  
        .social-networks {
          width: 6%;

          a {
            img {
              width: 19px;
            }
          }
        }

        .disclaimer {
          font-size: 9px;
          line-height: 14px;
        }
  
        .donors {
          a {
            &:nth-child(1) {
              img {
                width: 65px;
              }
            }
  
            &:nth-child(2) {
              img {
                width: 162.5px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .footer-content {
        padding: 40px 0 35px;

        .grouped-elements {
          width: 23%;

          .copyright {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
          }
    
          .address {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
          }
        }
  
        .contact-list {
          width: 16%;

          .contact-list__item {
            .info-icon {
              width: 17px;
            }
  
            a {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
  
        .social-networks {
          a {
            img {
              width: 17px;
            }
          }
        }

        .disclaimer {
          width: 24%;
          line-height: 13px;
        }
  
        .donors {
          width: 27%;

          a {
            &:nth-child(1) {
              img {
                width: 60px;
              }
            }
  
            &:nth-child(2) {
              img {
                width: 150px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      width: 90%;

      .footer-content {
        padding: 30px 0;

        .grouped-elements {
          width: 22%;

          .copyright {
            font-size: 12px;
            line-height: 18px;
          }
    
          .address {
            font-size: 12px;
            line-height: 18px;
          }
        }
  
        .contact-list {
          row-gap: 10px;
          width: 15%;
  
          .contact-list__item {
            column-gap: 10px;
  
            .info-icon {
              width: 14px;
            }
  
            a {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
  
        .social-networks {
          a {
            img {
              width: 14px;
            }
          }
        }

        .disclaimer {
          width: 27%;
          font-size: 8px;
          line-height: 12px;
        }
  
        .donors {
          width: 25%;

          a {
            &:nth-child(1) {
              img {
                width: 50px;
              }
            }
  
            &:nth-child(2) {
              img {
                width: 125px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      .footer-content {
        flex-direction: column;
        row-gap: 10px;
        padding: 22.5px 0;

        .footer-content-top-row {
          display: flex;
          justify-content: space-between;

          .grouped-elements {
            display: flex;
            justify-content: space-between;
            width: 54%;

            .copyright {
              width: 32%;
            }
      
            .address {
              width: 55%;
            }
          }

          .contact-list {
            width: 21%;
          }
  
          .social-networks {
            width: 10%;

            a {
              img {
                width: 16px;
              }
            }
          }
        }

        .footer-content-bottom-row {
          display: flex;
          justify-content: space-between;

          .disclaimer {
            width: 62%;
            font-size: 7px;
            line-height: 10px;
          }
    
          .donors {
            width: 32%;

            a {
              &:nth-child(1) {
                img {
                  width: 40px;
                }
              }
    
              &:nth-child(2) {
                img {
                  width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .footer-content {
        padding: 15px 0;

        .footer-content-top-row {
          .grouped-elements {
            width: 59%;
            
            .copyright {
              width: 34%;
              font-size: 10px;
              line-height: 16px;
            }
      
            .address {
              width: 58%;
              font-size: 10px;
              line-height: 16px;
            }
          }
    
          .contact-list {
            width: 25%;

            .contact-list__item {
              .info-icon {
                width: 16px;
              }
  
              a {
                font-size: 10px;
                line-height: 16px;
              }
            }
          }
    
          .social-networks {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            row-gap: 15px;
            width: 8%;
  
            a {
              img {
                width: 18px;
              }
            }
          }
        }

        .footer-content-bottom-row {
          .disclaimer {
            width: 52%;
            line-height: 9px;
          }
    
          .donors {
            width: 42%;
          }
        }
      }
    }
  }

  @media (max-width: 430px) {
    .wrapper {
      .footer-content {
        .footer-content-top-row {
          .grouped-elements {
            flex-direction: column;
            width: 41%;

            .copyright {
              width: 100%;
            }
      
            .address {
              width: 100%;
            }
          }

          .contact-list {
            width: 30%;
          }
    
          .social-networks {
            width: 10%;
  
            a {
              img {
                width: 20px;
              }
            }
          }
        }

        .footer-content-bottom-row {
          .disclaimer {
            width: 47%;
            font-size: 6px;
            line-height: 8px;
          }
    
          .donors {
            width: 48%;
          }
        }
      }
    }
  }
}