.activity-list__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .activity-list__cover {
    position: relative;
    display: flex;
    align-items: center;
    width: 48.5%;
    min-height: 400px;
    border-radius: 15px;
    overflow: hidden;

    .image-dimming {
      position: absolute;
      z-index: 1;
      border-radius: 15px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .activity-image {
      display: block;
      width: 100%;
      -webkit-transition: transform 1s;
      -moz-transition: transform 1s;
      -o-transition: transform 1s;
      -ms-transition: transform 1s;
      transition: transform 1s;
    }

    &:hover {
      .activity-image {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }

    .activity-title {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      width: 100%;
      -webkit-transition: top 1s;
      -moz-transition: top 1s;
      -o-transition: top 1s;
      -ms-transition: top 1s;
      transition: top 1s;

      .cover-icon {
        display: block;
        width: 40px;
      }

      .heading-two {
        padding: 0 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
      }
    }

    &:hover {
      .activity-title {
        top: 10%;
      }
    }

    .event-date {
      position: absolute;
      z-index: 1;
      bottom: 0;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -o-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      width: 100%;
      padding: 0 40px;
      font-family: Inter;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
      -webkit-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -o-transition-duration: 1s;
      -ms-transition-duration: 1s;
      transition-duration: 1s;
    }

    &:hover {
      .event-date {
        bottom: 10%;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }

  .activity-list__basic-info {
    width: 48.5%;

    .heading-three {
      padding-bottom: 40px;
      font-family: Inter;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
    }

    .basic-info-item {
      display: flex;
      align-items: center;
      column-gap: 15px;
      padding-bottom: 20px;

      .info-icon {
        display: block;
        width: 20px;
      }

      .paragraph {
        font-family: Inter;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }

      &:last-of-type {
        padding-bottom: 35px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .activity-list__item {
    .activity-list__cover {
      min-height: 375px;
  
      .activity-title {
        .heading-two {
          font-size: 27px;
          line-height: 33px;
        }
      }
  
      .event-date {
        font-size: 22px;
        line-height: 28px;
      }
    }
  
    .activity-list__basic-info {
      .heading-three {
        padding-bottom: 35px;
        font-size: 22px;
        line-height: 30px;
      }
  
      .basic-info-item {
        .info-icon {
          width: 18px;
        }
  
        .paragraph {
          line-height: 24px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .activity-list__item {
    .activity-list__cover {
      min-height: 350px;
  
      .activity-title {
        row-gap: 25px;

        .heading-two {
          padding: 0 30px;
          font-size: 25px;
          line-height: 30px;
        }
      }
  
      .event-date {
        padding: 0 30px;
        line-height: 26px;
      }

      &:hover {
        .event-date {
          bottom: 6%;
        }
      }
    }
  
    .activity-list__basic-info {
      .heading-three {
        padding-bottom: 30px;
        line-height: 28px;
      }
  
      .basic-info-item {
        padding-bottom: 15px;
  
        .paragraph {
          font-size: 16px;
        }
  
        &:last-of-type {
          padding-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .activity-list__item {
    .activity-list__cover {
      min-height: 320px;
  
      .activity-title {
        top: 15%;

        .heading-two {
          font-size: 23px;
          line-height: 27px;
        }
      }
  
      .event-date {
        font-size: 20px;
        line-height: 24px;
      }
    }
  
    .activity-list__basic-info {
      .heading-three {
        padding-bottom: 25px;
        font-size: 20px;
        line-height: 26px;
      }
  
      .basic-info-item {
        .info-icon {
          width: 16px;
        }
  
        .paragraph {
          line-height: 22px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .activity-list__item {
    .activity-list__cover {
      min-height: 295px;
      border-radius: 10px;
  
      .image-dimming {
        border-radius: 10px;
      }
  
      .activity-title {
        row-gap: 20px;
  
        .cover-icon {
          width: 30px;
        }
  
        .heading-two {
          padding: 0 20px;
          font-size: 20px;
          line-height: 24px;
        }
      }
  
      .event-date {
        padding: 0 20px;
        font-size: 18px;
        line-height: 22px;
      }

      &:hover {
        .event-date {
          bottom: 5%;
        }
      }
    }
  
    .activity-list__basic-info {
      .heading-three {
        padding-bottom: 20px;
        font-size: 18px;
        line-height: 24px;
      }
  
      .basic-info-item {
        column-gap: 10px;
        padding-bottom: 10px;
  
        .info-icon {
          width: 14px;
        }
  
        .paragraph {
          font-size: 14px;
          line-height: 20px;
        }
  
        &:last-of-type {
          padding-bottom: 25px;
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .activity-list__item {
    .activity-list__cover {
      min-height: 260px;

      &:hover {
        .activity-image {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -o-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
        }
      }
  
      .activity-title {
        top: 7.5%;
  
        .heading-two {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &:hover {
        .activity-title {
          top: 7.5%;
        }
      }
  
      .event-date {
        bottom: 7.5%;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        font-size: 16px;
        line-height: 20px;
      }

      &:hover {
        .event-date {
          bottom: 7.5%;
        }
      }
    }
  
    .activity-list__basic-info {
      .heading-three {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .activity-list__item {
    .activity-list__cover {
      min-height: 225px;
  
      .activity-title {
        .heading-two {
          font-size: 14px;
          line-height: 18px;
        }
      }
  
      .event-date {
        font-size: 14px;
        line-height: 18px;
      }
    }
  
    .activity-list__basic-info {
      .heading-three {
        font-size: 14px;
        line-height: 18px;
      }
  
      .basic-info-item {
        .paragraph {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 601px) {
  .activity-list__item {
    flex-direction: column;
    row-gap: 20px;

    .activity-list__cover {
      width: 100%;
      min-height: 275px;
  
      .activity-title {
        top: 10%;

        .heading-two {
          padding: 0 40px;
          font-size: 18px;
          line-height: 22px;
        }
      }

      &:hover {
        .activity-title {
          top: 10%;
        }
      }
  
      .event-date {
        bottom: 10%;
        padding: 0 40px;
        font-size: 18px;
        line-height: 22px;
      }

      &:hover {
        .event-date {
          bottom: 10%;
        }
      }
    }
  
    .activity-list__basic-info {
      width: 100%;

      .heading-three {
        padding-bottom: 15px;
        font-size: 18px;
        line-height: 24px;
      }
  
      .basic-info-item {
        column-gap: 7.5px;
        padding-bottom: 7.5px;
  
        .info-icon {
          width: 12px;
        }
  
        .paragraph {
          font-size: 12px;
          line-height: 16px;
        }
  
        &:last-of-type {
          padding-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .activity-list__item {
    .activity-list__cover {
      min-height: 240px;
  
      .activity-title {
        top: 7.5%;

        .heading-two {
          padding: 0 30px;
          font-size: 16px;
          line-height: 20px;
        }
      }

      &:hover {
        .activity-title {
          top: 7.5%;
        }
      }
  
      .event-date {
        bottom: 7.5%;
        padding: 0 30px;
        font-size: 16px;
        line-height: 20px;
      }

      &:hover {
        .event-date {
          bottom: 7.5%;
        }
      }
    }
  
    .activity-list__basic-info {
      .heading-three {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}