.ListLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-loader {
    display: block;
    width: 200px;
    -webkit-animation: logo-loader-rotation 2s linear 0s infinite normal none;
    -moz-animation: logo-loader-rotation 2s linear 0s infinite normal none;
    -o-animation: logo-loader-rotation 2s linear 0s infinite normal none;
    -ms-animation: logo-loader-rotation 2s linear 0s infinite normal none;
    animation: logo-loader-rotation 2s linear 0s infinite normal none;
  }

  @keyframes logo-loader-rotation {
    from {
      -webkit-transform: rotateY(0);
      -moz-transform: rotateY(0);
      -o-transform: rotateY(0);
      -ms-transform: rotateY(0);
      transform: rotateY(0);
    }
    to {
      -webkit-transform: rotateY(360deg);
      -moz-transform: rotateY(360deg);
      -o-transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }

  @media (max-width: 1600px) {
    .logo-loader {
      width: 180px;
    }
  }

  @media (max-width: 1440px) {
    .logo-loader {
      width: 170px;
    }
  }

  @media (max-width: 1280px) {
    .logo-loader {
      width: 160px;
    }
  }

  @media (max-width: 1024px) {
    .logo-loader {
      width: 140px;
    }
  }

  @media (max-width: 834px) {
    .logo-loader {
      width: 120px;
    }
  }

  @media (max-width: 601px) {
    .logo-loader {
      width: 100px;
    }
  }
}