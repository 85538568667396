.Paginator {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  
  .container {
    display: flex;
    column-gap: 20px;

    .previous,
    .next,
    .break,
    .page {
      width: 56px;
      height: 48px;
      border-radius: 28px;
      border: 1px solid #3D4910;
      cursor: pointer;
      overflow: hidden;

      &-link {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .previous,
    .next {
      &-link {
        .previous-label-container,
        .next-label-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          
          &:hover {
            background-color: #ECEFE3;
          }

          img {
            display: block;
            width: 8px;
          }
        }
      }

      &.disabled {
        cursor: not-allowed;

        .previous-link,
        .next-link {
          .previous-label-container,
          .next-label-container {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    .break {
      &:hover {
        background-color: #ECEFE3;
      }

      &-link {
        &:hover {
          color: #3D4910;
        }
      }
    }

    .page {
      font-family: Inter;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;

      &:hover {
        background-color: #ECEFE3;
      }

      &-link {
        &:hover {
          color: #3D4910;
        }
      }
    }

    .active {
      background-color: #3D4910;

      &:hover {
        background-color: #3D4910;
      }

      &-link {
        color: #FFFFFF;

        &:hover {
          color: #FFFFFF;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .Paginator {
    padding-top: 70px;
    
    .container {
      .previous,
      .next,
      .break,
      .page {
        width: 54px;
        height: 46px;
        border-radius: 27px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .Paginator {
    padding-top: 60px;
    
    .container {
      column-gap: 17.5px;
  
      .previous,
      .next,
      .break,
      .page {
        width: 52px;
        height: 44px;
        border-radius: 26px;
      }
  
      .page {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .Paginator {
    padding-top: 50px;
    
    .container {
      .previous,
      .next,
      .break,
      .page {
        width: 50px;
        height: 42px;
        border-radius: 25px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Paginator {
    padding-top: 40px;
    
    .container {
      column-gap: 15px;
  
      .previous,
      .next,
      .break,
      .page {
        width: 48px;
        height: 40px;
        border-radius: 24px;
      }
  
      .previous,
      .next {
        &-link {
          .previous-label-container,
          .next-label-container {
            img {
              width: 7px;
            }
          }
        }
      }
  
      .page {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

@media (max-width: 834px) {
  .Paginator {
    padding-top: 30px;
    
    .container {
      column-gap: 12.5px;
  
      .previous,
      .next,
      .break,
      .page {
        width: 38px;
        height: 32px;
        border-radius: 19px;
      }
      
      .previous,
      .next {
        &.disabled {
          .previous-link,
          .next-link {
            .previous-label-container,
            .next-label-container {
              background-color: #ECEFE3;

              &:hover {
                background-color: #ECEFE3;
              }
            }
          }
        }
      }
  
      .page {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

@media (max-width: 601px) {
  .Paginator {
    padding-top: 20px;
    
    .container {
      column-gap: 10px;
  
      .previous,
      .next,
      .break,
      .page {
        width: 28px;
        height: 24px;
        border-radius: 14px;
      }
  
      .previous,
      .next {
        &-link {
          .previous-label-container,
          .next-label-container {
            img {
              width: 6px;
            }
          }
        }
      }
  
      .page {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}