.community-indicator-list__item {
  width: -webkit-calc((100% - 60px)/3);
  width: -moz-calc((100% - 60px)/3);
  width: -o-calc((100% - 60px)/3);
  width: -ms-calc((100% - 60px)/3);
  width: calc((100% - 60px)/3);
  height: 240px;
  border-radius: 15px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .community-indicator-list__value {
    font-family: Inter;
    font-weight: 800;
    font-size: 64px;
    line-height: 78px;
  }

  .community-indicator-list__title {
    padding-top: 20px;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
  }
}

@media (max-width: 1600px) {
  .community-indicator-list__item {
    height: 200px;
  
    .community-indicator-list__value {
      font-size: 56px;
      line-height: 68px;
    }
  }
}

@media (max-width: 1440px) {
  .community-indicator-list__item {
    height: 180px;
    padding: 30px;
  
    .community-indicator-list__value {
      font-size: 48px;
      line-height: 63px;
    }
  
    .community-indicator-list__title {
      padding-top: 15px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (max-width: 1280px) {
  .community-indicator-list__item {
    .community-indicator-list__value {
      font-size: 40px;
      line-height: 58px;
    }
  }
}

@media (max-width: 1024px) {
  .community-indicator-list__item {
    width: -webkit-calc((100% - 50px)/3);
    width: -moz-calc((100% - 50px)/3);
    width: -o-calc((100% - 50px)/3);
    width: -ms-calc((100% - 50px)/3);
    width: calc((100% - 50px)/3);
    height: 140px;
    border-radius: 10px;
    padding: 20px;
  
    .community-indicator-list__value {
      font-size: 32px;
      line-height: 48px;
    }
  
    .community-indicator-list__title {
      padding-top: 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

@media (max-width: 834px) {
  .community-indicator-list__item {
    width: -webkit-calc((100% - 40px)/3);
    width: -moz-calc((100% - 40px)/3);
    width: -o-calc((100% - 40px)/3);
    width: -ms-calc((100% - 40px)/3);
    width: calc((100% - 40px)/3);
    height: 125px;
  
    .community-indicator-list__value {
      font-size: 25px;
      line-height: 36px;
    }
  }
}

@media (max-width: 601px) {
  .community-indicator-list__item {
    width: -webkit-calc((100% - 30px)/3);
    width: -moz-calc((100% - 30px)/3);
    width: -o-calc((100% - 30px)/3);
    width: -ms-calc((100% - 30px)/3);
    width: calc((100% - 30px)/3);
    height: 90px;
    border-radius: 5px;
    padding: 15px;
  
    .community-indicator-list__value {
      font-size: 18px;
      line-height: 24px;
    }
  
    .community-indicator-list__title {
      padding-top: 5px;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

@media (max-width: 430px) {
  .community-indicator-list__item {
    height: 105px;
  }
}