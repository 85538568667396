.company-profile-form {
  display: flex;
  flex-direction: column;
  padding-top: 65px;

  .company-profile-form-fields {
    .company-profile-form-fields-top-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .company-logo {
        display: block;
        width: 250px;
      }

      .company-profile-form-fields-top-row__right-side {
        display: flex;
        flex-direction: column;
        width: 70%;

        .ordinary-input-wrapper {
          &:nth-child(1) {
            width: 59%;

            .title {
              label {
                text-transform: uppercase;
              }
            }
          }
    
          &:nth-child(2) {
            width: 24%;

            .title {
              label {
                font-weight: normal;
              }
            }
          }
    
          &:nth-child(3) {
            width: 59%;

            .title {
              label {
                font-weight: normal;
              }
            }
          }

          input {
            border-radius: 0;
            border-width: 0 0 1px;
            border-style: solid;
            border-color: #3D4910;
  
            &:hover {
              border-color: #677C1A;
            }
        
            &:focus {
              border-color: #3D4910;
            }
        
            &.invalid {
              border-color: #C85252;
            }
          }
        }
      }
    }

    .company-profile-form-fields-bottom-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 50px;
      padding-top: 65px;

      .ordinary-input-wrapper {
        &:nth-child(1),
        &:nth-child(2) {
          width: 48%;
        }

        &:nth-child(4),
        &:nth-child(5) {
          width: 32%;
        }

        input {
          border-radius: 0;
          border-width: 0 0 1px;
          border-style: solid;
          border-color: #3D4910;

          &:hover {
            border-color: #677C1A;
          }
      
          &:focus {
            border-color: #3D4910;
          }
      
          &.invalid {
            border-color: #C85252;
          }
        }
      }

      .phone-input-wrapper {
        &:nth-child(3) {
          width: 32%;
        }
        
        input[class="form-control "],
        input[class="form-control invalid"],
        input[class="form-control open "],
        input[class="form-control open invalid"] {
          border-radius: 0;
          border-width: 0 0 1px;
          border-style: solid;
          border-color: #3D4910;
        }

        input[class="form-control "] {
          &:hover {
            border-color: #677C1A;
          }
        }
        
        input[class="form-control invalid"],
        input[class="form-control open invalid"] {
          border-color: #C85252;
        }
      }
    }
  }

  button[type="submit"] {
    align-self: center;
    margin-top: 50px;
  }
}

.notification {
  padding-top: 40px;
  font-family: Inter;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #C85252;
}

@media (max-width: 1600px) {
  .company-profile-form {
    padding-top: 55px;

    .company-profile-form-fields {
      .company-profile-form-fields-top-row {
        .company-logo {
          width: 220px;
        }
  
        .company-profile-form-fields-top-row__right-side {
          .ordinary-input-wrapper {
            &:nth-child(1),
            &:nth-child(3) {
              width: 62.5%;
            }
      
            &:nth-child(2) {
              width: 25.5%;
            }
          }
        }
      }
  
      .company-profile-form-fields-bottom-row {
        row-gap: 40px;
        padding-top: 55px;
      }
    }

    button[type="submit"] {
      margin-top: 40px;
    }
  }

  .notification {
    padding-top: 35px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 1440px) {
  .company-profile-form {
    padding-top: 45px;

    .company-profile-form-fields {
      .company-profile-form-fields-top-row {
        .company-logo {
          width: 205px;
        }
  
        .company-profile-form-fields-top-row__right-side {
          .ordinary-input-wrapper {
            &:nth-child(1),
            &:nth-child(3) {
              width: 66%;
            }
      
            &:nth-child(2) {
              width: 27%;
            }
          }
        }
      }
  
      .company-profile-form-fields-bottom-row {
        row-gap: 35px;
        padding-top: 45px;
      }
    }

    button[type="submit"] {
      margin-top: 35px;
    }
  }

  .notification {
    padding-top: 30px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1280px) {
  .company-profile-form {
    padding-top: 35px;

    .company-profile-form-fields {
      .company-profile-form-fields-top-row {
        .company-logo {
          width: 190px;
        }
  
        .company-profile-form-fields-top-row__right-side {
          .ordinary-input-wrapper {
            &:nth-child(1),
            &:nth-child(3) {
              width: 77.5%;
            }
      
            &:nth-child(2) {
              width: 32%;
            }
          }
        }
      }
  
      .company-profile-form-fields-bottom-row {
        row-gap: 30px;
        padding-top: 35px;
      }
    }

    button[type="submit"] {
      margin-top: 30px;
    }
  }

  .notification {
    padding-top: 25px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .company-profile-form {
    padding-top: 20px;

    .company-profile-form-fields {
      .company-profile-form-fields-top-row {
        .company-logo {
          width: 160px;
        }
  
        .company-profile-form-fields-top-row__right-side {
          .ordinary-input-wrapper {
            &:nth-child(1),
            &:nth-child(3) {
              width: 89.5%;
            }
      
            &:nth-child(2) {
              width: 37.5%;
            }
          }
        }
      }
  
      .company-profile-form-fields-bottom-row {
        row-gap: 20px;
        padding-top: 20px;
      }
    }

    button[type="submit"] {
      margin-top: 20px;
    }
  }

  .notification {
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 834px) {
  .company-profile-form {
    .company-profile-form-fields {
      .company-profile-form-fields-top-row {
        flex-direction: column;
        align-items: center;
        row-gap: 24px;

        .company-logo {
          width: 130px;
        }
  
        .company-profile-form-fields-top-row__right-side {
          width: 100%;

          .ordinary-input-wrapper {
            &:nth-child(1),
            &:nth-child(3) {
              width: 72%;
            }
      
            &:nth-child(2) {
              width: 30.5%;
            }
          }
        }
      }
  
      .company-profile-form-fields-bottom-row {
        row-gap: 10px;

        .ordinary-input-wrapper {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(5) {
            width: 100%;
          }

          &:nth-child(4) {
            width: 48%;
          }
        }
  
        .phone-input-wrapper {
          &:nth-child(3) {
            width: 48%;
          }
        }
      }
    }

    button[type="submit"] {
      margin-top: 10px;
    }
  }

  .notification {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 601px) {
  .company-profile-form {
    padding-top: 15px;

    .company-profile-form-fields {
      .company-profile-form-fields-top-row {
        .company-logo {
          width: 100px;
        }
  
        .company-profile-form-fields-top-row__right-side {
          .ordinary-input-wrapper {
            &:nth-child(1),
            &:nth-child(3) {
              width: 94%;
            }
      
            &:nth-child(2) {
              width: 40%;
            }
          }
        }
      }
  
      .company-profile-form-fields-bottom-row {
        row-gap: 0;
        padding-top: 15px;

        .ordinary-input-wrapper {
          &:nth-child(4) {
            width: 100%;
          }
        }

        .phone-input-wrapper {
          &:nth-child(3) {
            width: 100%;
          }
        }
      }
    }

    button[type="submit"] {
      margin-top: 0;
    }
  }

  .notification {
    padding-top: 15px;
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 430px) {
  .company-profile-form {
    .company-profile-form-fields {
      .company-profile-form-fields-top-row {
        .company-profile-form-fields-top-row__right-side {
          .ordinary-input-wrapper {
            &:nth-child(1),
            &:nth-child(3) {
              width: 100%;
            }
      
            &:nth-child(2) {
              width: 47.5%;
            }
          }
        }
      }
    }
  }
}