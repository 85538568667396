.Main {
  position: relative;

  .anchor {
    position: absolute;
  }

  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .main-content {
      padding: 80px 0;

      .main-content-top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo-big {
          display: block;
          height: 360px;
        }

        .main-content-top-row__right-side {
          width: 67.5%;

          .heading-one {
            font-family: Inter;
            font-weight: 800;
            font-size: 80px;
            line-height: 90px;
            white-space: pre-wrap;
          }

          .description-top {
            padding-top: 50px;
            font-family: Inter;
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
          }
        }
      }

      .main-content-bottom-row {
        padding-top: 80px;
        
        .render-item {
          position: relative;

          .image-dimming {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(31, 31, 31, 0.32);
          }

          img {
            display: block;
            width: 100%;
          }

          .slide-info {
            position: absolute;
            left: 10%;
            top: 10%;
            width: 70%;

            .heading-two {
              font-family: Inter;
              font-weight: 800;
              font-size: 112px;
              line-height: 112px;
              letter-spacing: 3.75px;
              text-align: left;
              white-space: normal;
              color: #FFFFFF;
            }
  
            .description-bottom {
              padding-top: 45px;
              font-family: Inter;
              font-weight: 400;
              font-size: 32px;
              line-height: 40px;
              text-align: left;
              white-space: normal;
              color: #FFFFFF;
            }
          }
        }

        .advertisement-banners {
          padding-top: 80px;
          display: flex;
          column-gap: 30px;
  
          .banner {
            width: -webkit-calc((100% - 30px)/2);
            width: -moz-calc((100% - 30px)/2);
            width: -o-calc((100% - 30px)/2);
            width: -ms-calc((100% - 30px)/2);
            width: calc((100% - 30px)/2);
            height: 340px;
            border-radius: 25px;
            background-color: #F7F8F4;
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .main-content {
        padding: 70px 0;
  
        .main-content-top-row {
          .logo-big {
            height: 316px;
          }

          .main-content-top-row__right-side {
            width: 66%;

            .heading-one {
              font-size: 70px;
              line-height: 80px;
            }
  
            .description-top {
              padding-top: 40px;
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
  
        .main-content-bottom-row {
          padding-top: 70px;
          
          .render-item {
            .slide-info {
              .heading-two {
                font-size: 97px;
                line-height: 97px;
                letter-spacing: 3.25px;
              }
    
              .description-bottom {
                padding-top: 40px;
                font-size: 28px;
                line-height: 36px;
              }
            }
          }
  
          .advertisement-banners {
            padding-top: 70px;
    
            .banner {
              height: 290px;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .main-content {
        padding: 60px 0;
  
        .main-content-top-row {
          .logo-big {
            height: 277px;
          }

          .main-content-top-row__right-side {
            width: 63.5%;
  
            .heading-one {
              font-size: 60px;
              line-height: 70px;
            }
  
            .description-top {
              padding-top: 35px;
              font-size: 24px;
              line-height: 32px;
            }
          }
        }
  
        .main-content-bottom-row {
          padding-top: 60px;
          
          .render-item {
            .slide-info {
              .heading-two {
                font-size: 87px;
                line-height: 87px;
                letter-spacing: 2.75px;
              }
    
              .description-bottom {
                padding-top: 35px;
                font-size: 24px;
                line-height: 32px;
              }
            }
          }
  
          .advertisement-banners {
            padding-top: 60px;
    
            .banner {
              height: 250px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .main-content {
        padding: 50px 0;
  
        .main-content-top-row {
          .logo-big {
            height: 238px;
          }

          .main-content-top-row__right-side {
            width: 66%;

            .heading-one {
              font-size: 50px;
              line-height: 60px;
            }
  
            .description-top {
              padding-top: 30px;
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
  
        .main-content-bottom-row {
          padding-top: 50px;
          
          .render-item {
            .slide-info {
              top: 12.5%;

              .heading-two {
                font-size: 77px;
                line-height: 77px;
                letter-spacing: 2.25px;
              }
    
              .description-bottom {
                padding-top: 30px;
                font-size: 20px;
                line-height: 28px;
              }
            }
          }
  
          .advertisement-banners {
            padding-top: 50px;
    
            .banner {
              height: 210px;
              border-radius: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .main-content {
        padding: 40px 0;
  
        .main-content-top-row {
          .logo-big {
            height: 199px;
          }

          .main-content-top-row__right-side {
            width: 65%;
  
            .heading-one {
              font-size: 40px;
              line-height: 50px;
            }
  
            .description-top {
              padding-top: 25px;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
  
        .main-content-bottom-row {
          padding-top: 40px;
          
          .render-item {
            .slide-info {
              .heading-two {
                font-size: 62px;
                line-height: 62px;
                letter-spacing: 1.75px;
              }
    
              .description-bottom {
                padding-top: 25px;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
  
          .advertisement-banners {
            padding-top: 40px;
            column-gap: 25px;
    
            .banner {
              width: -webkit-calc((100% - 25px)/2);
              width: -moz-calc((100% - 25px)/2);
              width: -o-calc((100% - 25px)/2);
              width: -ms-calc((100% - 25px)/2);
              width: calc((100% - 25px)/2);
              height: 160px;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .main-content {
        padding: 30px 0;
  
        .main-content-top-row {
          .logo-big {
            height: 114.5px;
          }

          .main-content-top-row__right-side {
            width: 65%;
            
            .heading-one {
              font-size: 31px;
              line-height: 41px;
            }
  
            .description-top {
              padding-top: 12.5px;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
  
        .main-content-bottom-row {
          padding-top: 30px;
          
          .render-item {
            .slide-info {
              top: 15%;

              .heading-two {
                font-size: 47px;
                line-height: 47px;
                letter-spacing: 1.25px;
              }
    
              .description-bottom {
                padding-top: 12.5px;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
  
          .advertisement-banners {
            padding-top: 30px;
    
            .banner {
              height: 150px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      .main-content {
        .main-content-top-row {
          .logo-big {
            height: 155.5px;
          }

          .main-content-top-row__right-side {
            width: 62%;
          }
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .main-content {
        padding: 20px 0;
  
        .main-content-top-row {
          .logo-big {
            height: 122px;
          }

          .main-content-top-row__right-side {
            .heading-one {
              font-size: 22px;
              line-height: 32px;
            }
  
            .description-top {
              padding-top: 10px;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
  
        .main-content-bottom-row {
          padding-top: 20px;
          
          .render-item {
            .slide-info {
              .heading-two {
                font-size: 32px;
                line-height: 32px;
                letter-spacing: 0.75px;
              }
    
              .description-bottom {
                padding-top: 10px;
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
  
          .advertisement-banners {
            padding-top: 20px;
            column-gap: 20px;
    
            .banner {
              width: -webkit-calc((100% - 20px)/2);
              width: -moz-calc((100% - 20px)/2);
              width: -o-calc((100% - 20px)/2);
              width: -ms-calc((100% - 20px)/2);
              width: calc((100% - 20px)/2);
              height: 140px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 430px) {
    .wrapper {
      .main-content {
        .main-content-top-row {
          .logo-big {
            height: 116px;
          }

          .main-content-top-row__right-side {
            width: 61.5%;

            .heading-one {
              font-size: 20px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 390px) {
    .wrapper {
      .main-content {
        .main-content-top-row {
          .logo-big {
            height: 113px;
          }

          .main-content-top-row__right-side {
            width: 63.5%;

            .heading-one {
              font-size: 19px;
              line-height: 29px;
            }
          }
        }
      }
    }
  }
}