.AboutUsPage {
  background-color: #F7F8F4;

  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .about-us-page-content {
      padding: 80px 0;

      .heading-one,
      .heading-two {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .about-us-page-content-top-row {
        display: flex;
        align-items: flex-start;
        column-gap: 17.5%;

        .previous-page-link {
          font-family: Inter;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-decoration: none;
          text-transform: uppercase;
        }

        .about-us-page-content-top-row__right-side {
          width: 60%;

          .about-us-activity {
            padding: 65px 0 80px;

            .paragraph {
              padding-top: 30px;
              font-family: Inter;
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
              text-align: justify;

              &:first-child {
                padding-top: 0;
              }
            }

            ul {
              li {
                margin-left: 20px;
                font-family: Inter;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: justify;
              }
            }
          }
        }
      }

      .description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }

      .notification {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #C85252;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .about-us-page-content {
        padding: 70px 0;
  
        .heading-one,
        .heading-two {
          font-size: 56px;
          line-height: 68px;
        }
  
        .about-us-page-content-top-row {
          column-gap: 15%;

          .previous-page-link {
            font-size: 19px;
            line-height: 28px;
          }
  
          .about-us-page-content-top-row__right-side {
            width: 65%;
            
            .about-us-activity {
              padding: 57.5px 0 70px;
  
              .paragraph {
                padding-top: 25px;
                font-size: 17px;
                line-height: 24px;
              }
  
              ul {
                li {
                  font-size: 17px;
                  line-height: 24px;
                }
              }
            }
          }
        }
  
        .description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        .notification {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }
  
  @media (max-width: 1440px) {
    .wrapper {
      .about-us-page-content {
        padding: 60px 0;
  
        .heading-one,
        .heading-two {
          font-size: 48px;
          line-height: 63px;
        }
  
        .about-us-page-content-top-row {
          .previous-page-link {
            font-size: 18px;
            line-height: 26px;
          }
  
          .about-us-page-content-top-row__right-side {
            .about-us-activity {
              padding: 52.5px 0 60px;
  
              .paragraph {
                font-size: 16px;
                line-height: 23px;
              }
  
              ul {
                li {
                  font-size: 16px;
                  line-height: 23px;
                }
              }
            }
          }
        }
  
        .description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        .notification {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
  
  @media (max-width: 1280px) {
    .wrapper {
      .about-us-page-content {
        padding: 50px 0;
  
        .heading-one,
        .heading-two {
          font-size: 40px;
          line-height: 58px;
        }
  
        .about-us-page-content-top-row {
          column-gap: 12.5%;

          .previous-page-link {
            font-size: 17px;
            line-height: 24px;
          }
  
          .about-us-page-content-top-row__right-side {
            width: 70%;

            .about-us-activity {
              padding: 47.5px 0 50px;
  
              .paragraph {
                padding-top: 20px;
                font-size: 15px;
                line-height: 22px;
              }
  
              ul {
                li {
                  font-size: 15px;
                  line-height: 22px;
                }
              }
            }
          }
        }
  
        .description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        .notification {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .wrapper {
      .about-us-page-content {
        padding: 40px 0;
  
        .heading-one,
        .heading-two {
          font-size: 32px;
          line-height: 48px;
        }
  
        .about-us-page-content-top-row {
          justify-content: space-between;
          column-gap: 0;
  
          .previous-page-link {
            font-size: 16px;
            line-height: 22px;
          }
  
          .about-us-page-content-top-row__right-side {
            width: 75%;
  
            .about-us-activity {
              padding: 40px 0;
  
              .paragraph {
                padding-top: 15px;
                font-size: 14px;
                line-height: 20px;
              }
  
              ul {
                li {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
        }
  
        .description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        .notification {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .about-us-page-content {
        padding: 30px 0;
  
        .heading-one,
        .heading-two {
          font-size: 25px;
          line-height: 36px;
        }
  
        .about-us-page-content-top-row {
          .previous-page-link {
            font-size: 14px;
            line-height: 18px;
          }
  
          .about-us-page-content-top-row__right-side {
            width: 80%;
  
            .about-us-activity {
              padding: 30px 0;
  
              .paragraph {
                font-size: 13px;
                line-height: 19px;
              }
  
              ul {
                li {
                  font-size: 13px;
                  line-height: 19px;
                }
              }
            }
          }
        }
  
        .description {
          font-size: 14px;
          line-height: 20px;
        }
  
        .notification {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      .about-us-page-content {
        .about-us-page-content-top-row {
          display: block;
  
          .about-us-page-content-top-row__right-side {
            width: 100%;
            padding-top: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .about-us-page-content {
        padding: 20px 0;
  
        .heading-one,
        .heading-two {
          font-size: 18px;
          line-height: 24px;
        }
  
        .about-us-page-content-top-row {
          .previous-page-link {
            font-size: 12px;
            line-height: 14px;
          }
  
          .about-us-page-content-top-row__right-side {
            padding-top: 20px;

            .about-us-activity {
              padding: 20px 0;
  
              .paragraph {
                padding-top: 10px;
                font-size: 12px;
                line-height: 18px;
              }
  
              ul {
                li {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }
          }
        }
  
        .description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        .notification {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}