.ordinary-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  .title {
    position: relative;

    label {
      font-family: Inter;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: -29px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 22px;
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    border-radius: 24px;
    border: 1px solid #3D4910;
    padding: 10px 15px;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #3D4910;

    &::placeholder {
      font-family: Inter;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: rgba(61, 73, 16, 0.7);
    }

    &:hover {
      border: 1px solid #677C1A;
    }

    &:focus {
      outline: none;
      border: 1px solid #3D4910;
    }

    &.invalid {
      border: 1px solid #C85252;
    }
  }

  input[type="search"] {
    width: 100%;
    border-radius: 0;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(61, 73, 16, 0.7);
    padding: 0 0 5px 35px;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #3D4910;

    &::placeholder {
      font-family: Inter;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: rgba(61, 73, 16, 0.7);
    }
  }

  .password-container {
    position: relative;
    width: 100%;

    input {
      padding: 10px 45px 10px 15px;
    }

    img {
      position: absolute;
      right: 15px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 25px;
      cursor: pointer;
    }
  }

  .warning {
    position: absolute;
    top: 100%;
    padding: 5px 0 0 16px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C85252;
  }
}

@media (max-width: 1600px) {
  .ordinary-input-wrapper {
    margin-bottom: 29px;

    .title {
      label {
        font-size: 22px;
        line-height: 29px;
      }

      img {
        right: -26px;
        width: 20px;
      }
    }
  
    input {
      border-radius: 23px;
      font-size: 17px;
      line-height: 24px;
  
      &::placeholder {
        font-size: 17px;
        line-height: 24px;
      }
    }
  
    input[type="search"] {
      font-size: 17px;
      line-height: 24px;
  
      &::placeholder {
        font-size: 17px;
        line-height: 24px;
      }
    }
  
    .warning {
      font-size: 15px;
      line-height: 19px;
    }
  }
}

@media (max-width: 1440px) {
  .ordinary-input-wrapper {
    margin-bottom: 28px;

    .title {
      label {
        font-size: 21px;
        line-height: 28px;
      }

      img {
        right: -25px;
        width: 19px;
      }
    }
  
    input {
      border-radius: 22.5px;
      font-size: 16px;
      line-height: 23px;
  
      &::placeholder {
        font-size: 16px;
        line-height: 23px;
      }
    }
  
    input[type="search"] {
      padding: 0 0 5px 30px;
      font-size: 16px;
      line-height: 23px;
  
      &::placeholder {
        font-size: 16px;
        line-height: 23px;
      }
    }
  
    .password-container {
      input {
        padding: 10px 42.5px 10px 15px;
      }
  
      img {
        width: 22.5px;
      }
    }

    .warning {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media (max-width: 1280px) {
  .ordinary-input-wrapper {
    margin-bottom: 27px;

    .title {
      label {
        font-size: 20px;
        line-height: 27px;
      }

      img {
        right: -24px;
        width: 18px;
      }
    }
  
    input {
      border-radius: 22px;
      font-size: 15px;
      line-height: 22px;
  
      &::placeholder {
        font-size: 15px;
        line-height: 22px;
      }
    }
  
    input[type="search"] {
      font-size: 15px;
      line-height: 22px;
  
      &::placeholder {
        font-size: 15px;
        line-height: 22px;
      }
    }
  
    .warning {
      font-size: 13px;
      line-height: 17px;
    }
  }
}

@media (max-width: 1024px) {
  .ordinary-input-wrapper {
    margin-bottom: 25px;

    .title {
      label {
        font-size: 18px;
        line-height: 24px;
      }

      img {
        right: -21px;
        width: 16px;
      }
    }
  
    input {
      border-radius: 21px;
      font-size: 14px;
      line-height: 20px;
  
      &::placeholder {
        font-size: 14px;
        line-height: 20px;
      }
    }
  
    input[type="search"] {
      padding: 0 0 2.5px 25px;
      font-size: 14px;
      line-height: 20px;
  
      &::placeholder {
        font-size: 14px;
        line-height: 20px;
      }
    }
  
    .password-container {
      input {
        padding: 10px 40px 10px 15px;
      }
  
      img {
        width: 20px;
      }
    }
  
    .warning {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

@media (max-width: 834px) {
  .ordinary-input-wrapper {
    margin-bottom: 24px;

    .title {
      label {
        font-size: 16px;
        line-height: 22px;
      }

      img {
        right: -19px;
        width: 14px;
      }
    }
  
    input {
      border-radius: 20.5px;
      font-size: 13px;
      line-height: 19px;
  
      &::placeholder {
        font-size: 13px;
        line-height: 19px;
      }
    }
  
    input[type="search"] {
      font-size: 13px;
      line-height: 19px;
  
      &::placeholder {
        font-size: 13px;
        line-height: 19px;
      }
    }
  
    .warning {
      font-size: 11px;
      line-height: 14px;
    }
  }
}

@media (max-width: 601px) {
  .ordinary-input-wrapper {
    margin-bottom: 23px;

    .title {
      label {
        font-size: 14px;
        line-height: 20px;
      }

      img {
        right: -17px;
        width: 12px;
      }
    }
  
    input {
      border-radius: 20px;
      font-size: 12px;
      line-height: 18px;
  
      &::placeholder {
        font-size: 12px;
        line-height: 18px;
      }
    }
  
    input[type="search"] {
      font-size: 12px;
      line-height: 18px;
  
      &::placeholder {
        font-size: 12px;
        line-height: 18px;
      }
    }
  
    .warning {
      font-size: 10px;
      line-height: 13px;
    }
  }
}