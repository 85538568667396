* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  color: #3D4910;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

input[type="file"] {
  display: none;
}

.App {
  .main {
    position: relative;
  }

  .hotline-button {
    position: fixed;
    z-index: 2;
    right: 2.5%;
    bottom: 5%;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border-radius: 26px;
    border: 1px solid #3D4910;
    padding: 15px 30px;
    background-color: #3D4910;
    box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
    cursor: pointer;
    
    &::after {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(50%) rotate(125deg);
      border-left: 30px solid #3D4910;
      border-top: 17.5px solid transparent;
      border-bottom: 17.5px solid transparent;
      content: "";
    }

    img {
      display: block;
      height: 20px;
    }

    span {
      font-family: Inter;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #FFFFFF;
    }
  }
  
  @media (max-width: 1440px) {
    .hotline-button {
      padding: 15px 25px;
    }
  }

  @media (max-width: 1280px) {
    .hotline-button {
      column-gap: 7.5px;
    }
  }

  @media (max-width: 1024px) {
    .hotline-button {
      border-radius: 25px;
      padding: 15px 20px;

      &::after {
        border-width: 16.5px 0 16.5px 28px;
      }

      img {
        height: 18px;
      }

      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  
  @media (max-width: 834px) {
    .hotline-button {
      border-radius: 20px;
      padding: 10px 15px;

      &::after {
        border-width: 11.5px 0 11.5px 18px;
      }
    }
  }
  
  @media (max-width: 601px) {
    .hotline-button {
      column-gap: 5px;
      border-radius: 19px;

      &::after {
        border-width: 10.5px 0 10.5px 16px;
      }

      img {
        height: 16px;
      }

      span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}