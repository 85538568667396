.article-list__item {
  text-decoration: none;

  .article-list__cover {
    border-radius: 15px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      -webkit-transition: transform 1s;
      -moz-transition: transform 1s;
      -o-transition: transform 1s;
      -ms-transition: transform 1s;
      transition: transform 1s;
    }
  }

  &:hover {
    .article-list__cover {
      img {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }

  .article-list__info {
    .heading-three {
      font-family: Inter;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;

      &:nth-child(1) {
        padding: 30px 0 40px;
      }
    }

    .info-item {
      display: flex;
      align-items: center;
      column-gap: 15px;

      &:nth-child(2) {
        padding-bottom: 15px;
      }

      .info-icon {
        display: block;
        width: 20px;
      }

      .paragraph {
        font-family: Inter;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .article-list__item {
    .article-list__info {
      .heading-three {
        font-size: 22px;
        line-height: 30px;
  
        &:nth-child(1) {
          padding: 25px 0 35px;
        }
      }
  
      .info-item {
        .info-icon {
          width: 18px;
        }

        .paragraph {
          font-size: 16px;
          line-height: 23px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .article-list__item {
    .article-list__info {
      .heading-three {
        line-height: 28px;
      }
  
      .info-item {
        .paragraph {
          line-height: 21px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .article-list__item {
    .article-list__info {
      .heading-three {
        font-size: 20px;
        line-height: 26px;
  
        &:nth-child(1) {
          padding: 20px 0 30px;
        }
      }
  
      .info-item {
        .info-icon {
          width: 16px;
        }

        .paragraph {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .article-list__item {
    .article-list__cover {
      border-radius: 10px;
    }
  
    .article-list__info {
      .heading-three {
        font-size: 18px;
        line-height: 24px;
  
        &:nth-child(1) {
          padding: 15px 0 25px;
        }
      }
  
      .info-item {
        column-gap: 10px;
  
        &:nth-child(2) {
          padding-bottom: 10px;
        }
  
        .info-icon {
          width: 14px;
        }
  
        .paragraph {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .article-list__item {
    &:hover {
      .article-list__cover {
        img {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -o-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
        }
      }
    }

    .article-list__info {
      .heading-three {
        font-size: 16px;
        line-height: 20px;
  
        &:nth-child(1) {
          padding: 15px 0 20px;
        }
      }
    }
  }
}

@media (max-width: 601px) {
  .article-list__item {
    .article-list__cover {
      border-radius: 5px;
    }
  
    .article-list__info {
      .heading-three {
        font-size: 14px;
        line-height: 16px;
  
        &:nth-child(1) {
          padding: 10px 0 15px;
        }
      }
  
      .info-item {
        column-gap: 7.5px;
  
        &:nth-child(2) {
          padding-bottom: 5px;
        }
  
        .info-icon {
          width: 12px;
        }
  
        .paragraph {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
}