.ManagerInfo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    padding: 10px;
    min-width: 50px;
    border: 1px solid #ccc;
    p {
        padding: 5px;
    }
}