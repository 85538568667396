.Header {
  background-color: #FFFFFF;
  box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);

  &.fixed {
    position: fixed;
    z-index: 5;
    width: 100%;
  }

  &.hidden {
    visibility: hidden;
  }

  .wrapper {
    position: relative;
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .header-content {
      padding: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .hamburger-menu {
        position: relative;
        z-index: 2;
        width: 30px;
        height: 30px;
        overflow: hidden;
        cursor: pointer;

        span {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 30px;
          height: 4px;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          -webkit-transition: transform 0.5s;
          -moz-transition: transform 0.5s;
          -o-transition: transform 0.5s;
          -ms-transition: transform 0.5s;
          transition: transform 0.5s;
          background-color: #3D4910;

          &:nth-child(1) {
            top: -webkit-calc(50% - 10px);
            top: -moz-calc(50% - 10px);
            top: -o-calc(50% - 10px);
            top: -ms-calc(50% - 10px);
            top: calc(50% - 10px);
          }
          
          &:nth-child(3) {
            top: -webkit-calc(50% + 10px);
            top: -moz-calc(50% + 10px);
            top: -o-calc(50% + 10px);
            top: -ms-calc(50% + 10px);
            top: calc(50% + 10px);
          }
        }

        &.active {
          span {
            &:nth-child(2) {
              -webkit-transform: translate(-150%, -50%);
              -moz-transform: translate(-150%, -50%);
              -o-transform: translate(-150%, -50%);
              -ms-transform: translate(-150%, -50%);
              transform: translate(-150%, -50%);
            }
            
            &:nth-child(1) {
              top: 50%;
              -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              -moz-transform: translate(-50%, -50%) rotate(-45deg);
              -o-transform: translate(-50%, -50%) rotate(-45deg);
              -ms-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg);
            }
            
            &:nth-child(3) {
              top: 50%;
              -webkit-transform: translate(-50%, -50%) rotate(45deg);
              -moz-transform: translate(-50%, -50%) rotate(45deg);
              -o-transform: translate(-50%, -50%) rotate(45deg);
              -ms-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
        }
      }

      a {
        .logo-small {
          position: relative;
          z-index: 2;
          display: block;
          width: 60px;
        }
      }

      .navigation {
        position: relative;
        z-index: 2;
        width: 70%;

        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          list-style-type: none;

          .main-dropdown-list,
          .documents-dropdown-list,
          .proposals-for-CCI-dropdown-list {
            position: relative;

            .dropdown-button {
              display: flex;
              align-items: center;
              column-gap: 7.5px;
              border: none;
              background-color: transparent;
              cursor: pointer;
          
              span {
                font-family: Inter;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #3D4910;
              }
          
              img {
                display: block;
                width: 13px;
              }
            }
          
            ul {
              position: absolute;
              top: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              list-style-type: none;
              border-radius: 0 0 12.5px 12.5px;
              padding: 12.5px 0;
              background-color: #FFFFFF;
              box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
              cursor: pointer;
          
              .main-dropdown-list__item,
              .documents-dropdown-list__item,
              .proposals-for-CCI-dropdown-list__item {
                width: 100%;

                .main-dropdown-list__link,
                .documents-dropdown-list__link,
                .proposals-for-CCI-dropdown-list__link {
                  display: block;
                  padding: 7.5px 30px;
                  font-family: Inter;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 26px;
                  text-transform: uppercase;
                  color: #3D4910;
                  text-decoration: none;
                }
          
                &:hover {
                  background-color: #E9EDD7;
                }
              }
            }
          
            &.active {
              .dropdown-button {
                img {
                  -webkit-transform: rotate(-180deg);
                  -moz-transform: rotate(-180deg);
                  -o-transform: rotate(-180deg);
                  -ms-transform: rotate(-180deg);
                  transform: rotate(-180deg);
                }
              }
            }
          }

          .main-dropdown-list {
            ul {
              width: 200px;
            }
          }

          .documents-dropdown-list {
            ul {
              width: 220px;
            }
          }

          .proposals-for-CCI-dropdown-list {
            ul {
              width: 330px;
            }
          }

          .list__item {
            .list__link,
            .list__log-in,
            .list__log-out {
              display: block;
              font-family: Inter;
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              color: #3D4910;
              text-decoration: none;
            }

            .list__log-in,
            .list__log-out {
              cursor: pointer;
            }
          }
        }
      }

      .folding-screen {
        display: block;
        position: absolute;
        left: -12.5%;
        z-index: 1;
        width: 125%;
        height: 100%;
        background-color: #FFFFFF;
      }

      .search {
        position: relative;
        z-index: 2;
        border: none;
        background-color: transparent;
        cursor: pointer;

        img {
          display: block;
          width: 20px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .header-content {
        a {
          .logo-small {
            width: 55px; 
          }
        }
    
        .navigation {
          width: 72.5%;

          .list {
            .main-dropdown-list,
            .documents-dropdown-list,
            .proposals-for-CCI-dropdown-list {
              .dropdown-button {
                span {
                  font-size: 18px;
                  line-height: 27px;
                }
              }
              
              ul {
                .main-dropdown-list__item,
                .documents-dropdown-list__item,
                .proposals-for-CCI-dropdown-list__item {
                  .main-dropdown-list__link,
                  .documents-dropdown-list__link,
                  .proposals-for-CCI-dropdown-list__link {
                    font-size: 16px;
                    line-height: 23px;
                  }
                }
              }
            }

            .main-dropdown-list {
              ul {
                width: 185px;
              }
            }
  
            .documents-dropdown-list {
              ul {
                width: 205px;
              }
            }
  
            .proposals-for-CCI-dropdown-list {
              ul {
                width: 300px;
              }
            }

            .list__item {
              .list__link,
              .list__log-in,
              .list__log-out {
                font-size: 18px;
                line-height: 27px;
              }
            }
          }
        }

        .search {
          img {
            width: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .header-content {
        padding: 25px 0;

        a {
          .logo-small {
            width: 50px; 
          }
        }
    
        .navigation {
          .list {
            .main-dropdown-list,
            .documents-dropdown-list,
            .proposals-for-CCI-dropdown-list {
              .dropdown-button {
                span {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              
              ul {
                border-radius: 0 0 9px 9px;
                padding: 9px 0;

                .main-dropdown-list__item,
                .documents-dropdown-list__item,
                .proposals-for-CCI-dropdown-list__item {
                  .main-dropdown-list__link,
                  .documents-dropdown-list__link,
                  .proposals-for-CCI-dropdown-list__link {
                    padding: 6px 25px;
                    font-size: 14px;
                    line-height: 21px;
                  }
                }
              }
            }

            .main-dropdown-list {
              ul {
                width: 160px;
              }
            }
  
            .documents-dropdown-list {
              ul {
                width: 175px;
              }
            }
  
            .proposals-for-CCI-dropdown-list {
              ul {
                width: 260px;
              }
            }

            .list__item {
              .list__link,
              .list__log-in,
              .list__log-out {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }

        .search {
          img {
            width: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .header-content {
        a {
          .logo-small {
            width: 45px; 
          }
        }
    
        .navigation {
          width: 75%;
          
          .list {
            .main-dropdown-list,
            .documents-dropdown-list,
            .proposals-for-CCI-dropdown-list {
              .dropdown-button {
                column-gap: 5px;

                span {
                  font-size: 14px;
                  line-height: 21px;
                }

                img {
                  width: 10px;
                }
              }
              
              ul {
                .main-dropdown-list__item,
                .documents-dropdown-list__item,
                .proposals-for-CCI-dropdown-list__item {
                  .main-dropdown-list__link,
                  .documents-dropdown-list__link,
                  .proposals-for-CCI-dropdown-list__link {
                    font-size: 12px;
                    line-height: 19px;
                  }
                }
              }
            }

            .main-dropdown-list {
              ul {
                width: 145px;
              }
            }
  
            .documents-dropdown-list {
              ul {
                width: 160px;
              }
            }
  
            .proposals-for-CCI-dropdown-list {
              ul {
                width: 230px;
              }
            }
            
            .list__item {
              .list__link,
              .list__log-in,
              .list__log-out {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }

        .search {
          img {
            width: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .header-content {
        padding: 20px 0;

        a {
          .logo-small {
            width: 40px; 
          }
        }
    
        .navigation {
        width: 77.5%;

          .list {
            .main-dropdown-list,
            .documents-dropdown-list,
            .proposals-for-CCI-dropdown-list {
              .dropdown-button {
                span {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
              
              ul {
                .main-dropdown-list__item,
                .documents-dropdown-list__item,
                .proposals-for-CCI-dropdown-list__item {
                  .main-dropdown-list__link,
                  .documents-dropdown-list__link,
                  .proposals-for-CCI-dropdown-list__link {
                    font-size: 10px;
                    line-height: 16px;
                  }
                }
              }
            }

            .main-dropdown-list {
              ul {
                width: 130px;
              }
            }
  
            .documents-dropdown-list {
              ul {
                width: 140px;
              }
            }
  
            .proposals-for-CCI-dropdown-list {
              ul {
                width: 200px;
              }
            }

            .list__item {
              .list__link,
              .list__log-in,
              .list__log-out {
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }

        .search {
          img {
            width: 12px;
          }
        }
      }
    }
  }

  @media (min-width: 835px) {
    .wrapper {
      .header-content {
        .hamburger-menu {
          display: none;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .header-content {
        a {
          display: none;
        }

        .navigation {
          position: absolute;
          z-index: 1;
          left: -5.5%;
          bottom: 0;
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -o-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
          -webkit-transition-duration: 0.5s;
          -moz-transition-duration: 0.5s;
          -o-transition-duration: 0.5s;
          -ms-transition-duration: 0.5s;
          transition-duration: 0.5s;
          width: 200px;
          border-radius: 0 0 7.5px 7.5px;
          padding: 7.5px 0;
          background-color: #FFFFFF;
          box-shadow: none;
        
          &.active {
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
            -o-transform: translateY(100%);
            -ms-transform: translateY(100%);
            transform: translateY(100%);
            box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
          }
          
          .list {
            flex-direction: column;
            align-items: flex-start;

            .main-dropdown-list,
            .documents-dropdown-list,
            .proposals-for-CCI-dropdown-list {
              width: 100%;
              padding: 7.5px 25px;

              .dropdown-button {
                span {
                  color: rgba(61, 73, 16, 0.7);
                }
              }
              
              ul {
                left: 100%;
                top: 0;
                border-radius: 0;
                padding: 5px 0;
                
                .main-dropdown-list__item,
                .documents-dropdown-list__item,
                .proposals-for-CCI-dropdown-list__item {
                  .main-dropdown-list__link,
                  .documents-dropdown-list__link,
                  .proposals-for-CCI-dropdown-list__link {
                    display: block;
                    padding: 5px 20px;
                    color: rgba(61, 73, 16, 0.7);
                  }
                }
              }

              &.active {
                .dropdown-button {
                  img {
                    -webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -o-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                  }
                }
              }
            }

            .main-dropdown-list {
              ul {
                width: 120px;
              }
            }
  
            .documents-dropdown-list {
              ul {
                width: 130px;
              }
            }
  
            .proposals-for-CCI-dropdown-list {
              ul {
                width: 130px;
              }
            }
        
            .list__item {
              width: 100%;

              .list__link,
              .list__log-in,
              .list__log-out {
                padding: 7.5px 25px;
                color: rgba(61, 73, 16, 0.7);
              }

              .list__log-in,
              .list__log-out {
                &:hover {
                  background-color: #FFFFFF;
                }
              }

              &:hover {
                background-color: #E9EDD7;
              }
            }
          }
        }

        .folding-screen {
          left: -5.5%;
          width: 111%;
        }

        .search {
          left: 40%;

          img {
            width: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      .header-content {
        .search {
          left: 37.5%;
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .header-content {
        .search {
          left: 34%;
        }
      }
    }
  }

  @media (max-width: 430px) {
    .wrapper {
      .header-content {
        .search {
          left: 30%;
        }
      }
    }
  }
}