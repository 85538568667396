.Adminpanel,
.AdminpanelBlock,
.AdminpanelContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Adminpanel {
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
}

.AdminpanelBlock {
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100% - 60px);
  margin: 60px 0 0 0;
}

.AdminpanelContent {
  align-items: flex-start;
  width: calc(100vw - 170px);
  min-height: 100vh;
  border: 1px solid grey;
}