.vip-company-list__item {
  height: 100%;
  border-radius: 15px;
  box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  .vip-company-list__logo {
    display: block;
    width: 100px;
  }
  
  .vip-company-list__info {
    .primary-paragraph {
      padding: 15px 0;
      font-family: Inter;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-align: center;
    }
  
    .secondary-paragraph {
      font-family: Inter;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}

@media (max-width: 1600px) {
  .vip-company-list__item {
    .vip-company-list__logo {
      width: 90px;
    }
    
    .vip-company-list__info {
      .primary-paragraph {
        font-size: 16px;
        line-height: 23px;
      }
    
      .secondary-paragraph {
        line-height: 22px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .vip-company-list__item {
    .vip-company-list__logo {
      width: 82.5px;
    }
    
    .vip-company-list__info {
      .primary-paragraph {
        font-size: 14px;
        line-height: 21px;
      }
    
      .secondary-paragraph {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .vip-company-list__item {
    .vip-company-list__logo {
      width: 75px;
    }
    
    .vip-company-list__info {
      .primary-paragraph {
        font-size: 12px;
        line-height: 19px;
      }
    
      .secondary-paragraph {
        line-height: 18px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .vip-company-list__item {
    .vip-company-list__logo {
      width: 65px;
    }
    
    .vip-company-list__info {
      .primary-paragraph {
        font-size: 12px;
        line-height: 16px;
      }
    
      .secondary-paragraph {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}

@media (max-width: 601px) {
  .vip-company-list__item {
    .vip-company-list__logo {
      width: 60px;
    }
  }
}

@media (max-width: 430px) {
  .vip-company-list__item {
    .vip-company-list__logo {
      width: 55px;
    }

    .vip-company-list__info {
      .primary-paragraph {
        font-size: 10px;
        line-height: 14px;
      }
    
      .secondary-paragraph {
        font-size: 8px;
        line-height: 12px;
      }
    }
  }
}