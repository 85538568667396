.ArbitrationCourt {
  background-color: #F7F8F4;

  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .arbitration-court-content {
      padding: 80px 0;

      .heading-one,
      .heading-two {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }

      .arbitration-court-content-top-row {
        display: flex;
        align-items: flex-start;
        column-gap: 17.5%;

        .previous-page-link {
          font-family: Inter;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-decoration: none;
          text-transform: uppercase;
        }

        .arbitration-court-content-top-row__right-side {
          width: 60%;
          padding-bottom: 80px;
        }
      }

      .arbitration-court-document-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .arbitration-court-document-list__item {
          width: -webkit-calc((100% - 90px)/4);
          width: -moz-calc((100% - 90px)/4);
          width: -o-calc((100% - 90px)/4);
          width: -ms-calc((100% - 90px)/4);
          width: calc((100% - 90px)/4);
          border-radius: 15px;
          padding: 40px 35px;
          background-color: #FFFFFF;
          box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          
          .arbitration-court-document-list__title {
            font-family: Inter;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: center;
            text-transform: uppercase;
          }

          .buttons {
            button {
              width: 100%;
  
              &:first-of-type {
                margin: 35px 0;
              }
            }
          }
        }
      }
      
      .heading-two_form {
        padding-top: 80px;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .arbitration-court-content {
        padding: 70px 0;
  
        .heading-one,
        .heading-two {
          font-size: 56px;
          line-height: 68px;
        }
  
        .description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        .arbitration-court-content-top-row {
          column-gap: 15%;

          .previous-page-link {
            font-size: 19px;
            line-height: 28px;
          }
  
          .arbitration-court-content-top-row__right-side {
            width: 65%;
            padding-bottom: 70px;
          }
        }
  
        .arbitration-court-document-list {
          .arbitration-court-document-list__item {
            padding: 35px 20px;
            
            .arbitration-court-document-list__title {
              font-size: 15px;
              line-height: 22px;
            }
          }
        }
        
        .heading-two_form {
          padding-top: 70px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .arbitration-court-content {
        padding: 60px 0;
  
        .heading-one,
        .heading-two {
          font-size: 48px;
          line-height: 63px;
        }
  
        .description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        .arbitration-court-content-top-row {
          .previous-page-link {
            font-size: 18px;
            line-height: 26px;
          }
  
          .arbitration-court-content-top-row__right-side {
            padding-bottom: 60px;
          }
        }
  
        .arbitration-court-document-list {
          .arbitration-court-document-list__item {
            width: -webkit-calc((100% - 75px)/4);
            width: -moz-calc((100% - 75px)/4);
            width: -o-calc((100% - 75px)/4);
            width: -ms-calc((100% - 75px)/4);
            width: calc((100% - 75px)/4);
            padding: 30px 20px;

            .arbitration-court-document-list__title {
              font-size: 14px;
              line-height: 21px;
            }

            .buttons {
              button {
                &:first-of-type {
                  margin: 30px 0;
                }

                a {
                  padding: 15px;
                }
              }
            }
          }
        }
        
        .heading-two_form {
          padding-top: 60px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .arbitration-court-content {
        padding: 50px 0;
  
        .heading-one,
        .heading-two {
          font-size: 40px;
          line-height: 58px;
        }
  
        .description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        .arbitration-court-content-top-row {
          column-gap: 12.5%;

          .previous-page-link {
            font-size: 17px;
            line-height: 24px;
          }
  
          .arbitration-court-content-top-row__right-side {
            width: 70%;
            padding-bottom: 50px;
          }
        }
  
        .arbitration-court-document-list {
          justify-content: space-evenly;
          row-gap: 50px;

          .arbitration-court-document-list__item {
            width: 35%;
            padding: 30px;
            
            .arbitration-court-document-list__title {
              font-size: 13px;
              line-height: 20px;
            }

            .buttons {
              button {
                a {
                  padding: 15px 25px;
                }
              }
            }
          }
        }
        
        .heading-two_form {
          padding-top: 50px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .arbitration-court-content {
        padding: 40px 0;
  
        .heading-one,
        .heading-two {
          font-size: 32px;
          line-height: 48px;
        }
  
        .description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        .arbitration-court-content-top-row {
          justify-content: space-between;
          column-gap: 0;
  
          .previous-page-link {
            font-size: 16px;
            line-height: 22px;
          }
  
          .arbitration-court-content-top-row__right-side {
            width: 75%;
            padding-bottom: 40px;
          }
        }
  
        .arbitration-court-document-list {
          row-gap: 40px;

          .arbitration-court-document-list__item {
            width: 40%;
            border-radius: 10px;
            
            .arbitration-court-document-list__title {
              font-size: 12px;
              line-height: 18px;
            }

            .buttons {
              button {
                &:first-of-type {
                  margin: 25px 0;
                }

                a {
                  padding: 15px 20px;
                }
              }
            }
          }
        }
        
        .heading-two_form {
          padding-top: 40px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .arbitration-court-content {
        padding: 30px 0;
  
        .heading-one,
        .heading-two {
          font-size: 25px;
          line-height: 36px;
        }
  
        .description {
          font-size: 14px;
          line-height: 20px;
        }
  
        .arbitration-court-content-top-row {
          .previous-page-link {
            font-size: 14px;
            line-height: 18px;
          }
  
          .arbitration-court-content-top-row__right-side {
            width: 80%;
            padding-bottom: 30px;
          }
        }
  
        .arbitration-court-document-list {
          row-gap: 30px;

          .arbitration-court-document-list__item {
            width: 42.5%;
            padding: 25px;
            
            .arbitration-court-document-list__title {
              font-size: 11px;
              line-height: 16px;
            }

            .buttons {
              button {
                a {
                  padding: 10px 15px;
                }
              }
            }
          }
        }
        
        .heading-two_form {
          padding-top: 30px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      .arbitration-court-content {
        .arbitration-court-content-top-row {
          display: block;
  
          .arbitration-court-content-top-row__right-side {
            width: 100%;
            padding-top: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .arbitration-court-content {
        padding: 20px 0;
  
        .heading-one,
        .heading-two {
          font-size: 18px;
          line-height: 24px;
        }
  
        .description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        .arbitration-court-content-top-row {
          .previous-page-link {
            font-size: 12px;
            line-height: 14px;
          }
  
          .arbitration-court-content-top-row__right-side {
            padding: 20px 0;
          }
        }
  
        .arbitration-court-document-list {
          justify-content: space-between;
          row-gap: 20px;

          .arbitration-court-document-list__item {
            width: -webkit-calc((100% - 20px)/2);
            width: -moz-calc((100% - 20px)/2);
            width: -o-calc((100% - 20px)/2);
            width: -ms-calc((100% - 20px)/2);
            width: calc((100% - 20px)/2);
            padding: 20px 15px;
            
            .arbitration-court-document-list__title {
              font-size: 10px;
              line-height: 14px;
            }

            .buttons {
              button {
                &:first-of-type {
                  margin: 20px 0;
                }

                a {
                  padding: 10px 12.5px;
                }
              }
            }
          }
        }
        
        .heading-two_form {
          padding-top: 20px;
        }
      }
    }
  }

  @media (max-width: 430px) {
    .wrapper {
      .arbitration-court-content {
        .arbitration-court-document-list {
          .arbitration-court-document-list__item {
            padding: 20px 10px;

            .buttons {
              button {
                a {
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 390px) {
    .wrapper {
      .arbitration-court-content {
        .arbitration-court-document-list {
          .arbitration-court-document-list__item {
            .buttons {
              button {
                a {
                  padding: 7.5px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    .wrapper {
      .arbitration-court-content {
        .arbitration-court-document-list {
          .arbitration-court-document-list__item {
            .buttons {
              button {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}