.manager-list__item {
  width: -webkit-calc((100% - 60px)/3);
  width: -moz-calc((100% - 60px)/3);
  width: -o-calc((100% - 60px)/3);
  width: -ms-calc((100% - 60px)/3);
  width: calc((100% - 60px)/3);

  .manager-list__person-photo {
    border-radius: 15px;
    background-color: #F7F8F4;
    box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }

  .manager-list__person-info {
    .person-info-item {
      &:nth-child(1) {
        padding: 25px 0;
      }

      &:nth-child(2) {
        padding-bottom: 25px;
      }

      &:nth-child(3) {
        padding-bottom: 15px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        display: flex;
        column-gap: 15px;
        align-items: center;

        .info-icon {
          display: block;
          width: 20px;
        }
      }

      .primary-paragraph {
        font-family: Inter;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }
      
      .secondary-paragraph {
        font-family: Inter;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .manager-list__item {
    .manager-list__person-info {
      .person-info-item {
        &:nth-child(1) {
          padding: 25px 0 20px;
        }
  
        &:nth-child(2) {
          padding-bottom: 20px;
        }
  
        &:nth-child(3),
        &:nth-child(4) {
          .info-icon {
            width: 18px;
          }
        }
  
        .primary-paragraph {
          font-size: 21px;
          line-height: 28px;
        }
        
        .secondary-paragraph {
          font-size: 16px;
          line-height: 23px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .manager-list__item {
    .manager-list__person-info {
      .person-info-item {
        &:nth-child(1) {
          padding: 20px 0;
        }
  
        &:nth-child(3) {
          padding-bottom: 10px;
        }
  
        &:nth-child(3),
        &:nth-child(4) {
          column-gap: 10px;
  
          .info-icon {
            width: 16px;
          }
        }
  
        .primary-paragraph {
          font-size: 19px;
          line-height: 25px;
        }
        
        .secondary-paragraph {
          line-height: 21px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .manager-list__item {
    .manager-list__person-info {
      .person-info-item {
        &:nth-child(1) {
          padding: 20px 0 15px;
        }
  
        &:nth-child(2) {
          padding-bottom: 15px;
        }
  
        &:nth-child(3),
        &:nth-child(4) {
          .info-icon {
            width: 14px;
          }
        }
  
        .primary-paragraph {
          font-size: 17px;
          line-height: 22px;
        }
        
        .secondary-paragraph {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .manager-list__item {
    width: -webkit-calc((100% - 50px)/3);
    width: -moz-calc((100% - 50px)/3);
    width: -o-calc((100% - 50px)/3);
    width: -ms-calc((100% - 50px)/3);
    width: calc((100% - 50px)/3);
  
    .manager-list__person-photo {
      border-radius: 10px;
    }
  
    .manager-list__person-info {
      .person-info-item {
        &:nth-child(1) {
          padding: 15px 0 10px;
        }
  
        &:nth-child(2) {
          padding-bottom: 10px;
        }
  
        &:nth-child(3),
        &:nth-child(4) {
          .info-icon {
            width: 12px;
          }
        }
  
        .primary-paragraph {
          font-size: 14px;
          line-height: 18px;
        }
        
        .secondary-paragraph {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

@media (max-width: 601px) {
  .manager-list__item {
    width: -webkit-calc((100% - 20px)/2);
    width: -moz-calc((100% - 20px)/2);
    width: -o-calc((100% - 20px)/2);
    width: -ms-calc((100% - 20px)/2);
    width: calc((100% - 20px)/2);
  
    .manager-list__person-photo {
      border-radius: 5px;
    }
  
    .manager-list__person-info {
      .person-info-item {
        &:nth-child(1) {
          padding: 10px 0 5px;
        }
  
        &:nth-child(2),
        &:nth-child(3) {
          padding-bottom: 5px;
        }
  
        .primary-paragraph {
          font-size: 12px;
          line-height: 16px;
        }
        
        .secondary-paragraph {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
}