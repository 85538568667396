.CommunityPage {
  background-color: #F7F8F4;
  
  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .community-page-content {
      padding: 80px 0;

      .community-page-content-top-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .previous-page-link {
          font-family: Inter;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-decoration: none;
          text-transform: uppercase;
        }
  
        .heading-one {
          width: 78%;
          font-family: Inter;
          font-weight: 800;
          font-size: 56px;
          line-height: 68px;
          text-transform: uppercase;
          white-space: pre-wrap;
        }
      }
      
      .notification {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #C85252;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .community-page-content {
        padding: 70px 0;
  
        .community-page-content-top-row {
          .previous-page-link {
            font-size: 19px;
            line-height: 28px;
          }
    
          .heading-one {
            width: 75%;
            font-size: 48px;
            line-height: 59px;
          }
        }
        
        .notification {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .community-page-content {
        padding: 60px 0;
  
        .community-page-content-top-row {
          .previous-page-link {
            font-size: 18px;
            line-height: 26px;
          }
    
          .heading-one {
            font-size: 40px;
            line-height: 50px;
          }
        }
        
        .notification {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .community-page-content {
        padding: 50px 0;
  
        .community-page-content-top-row {
          .previous-page-link {
            font-size: 17px;
            line-height: 24px;
          }
    
          .heading-one {
            font-size: 32px;
            line-height: 41px;
          }
        }
        
        .notification {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .community-page-content {
        padding: 40px 0;
  
        .community-page-content-top-row {
          .previous-page-link {
            font-size: 16px;
            line-height: 22px;
          }
    
          .heading-one {
            font-size: 24px;
            line-height: 32px;
          }
        }
        
        .notification {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .community-page-content {
        padding: 30px 0;
  
        .community-page-content-top-row {
          .previous-page-link {
            font-size: 14px;
            line-height: 18px;
          }
    
          .heading-one {
            font-size: 21px;
            line-height: 28px;
          }
        }
        
        .notification {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .community-page-content {
        padding: 20px 0;
  
        .community-page-content-top-row {
          display: block;

          .previous-page-link {
            font-size: 12px;
            line-height: 14px;
          }
    
          .heading-one {
            width: 100%;
            padding-top: 20px;
            font-size: 18px;
            line-height: 24px;
            white-space: normal;
          }
        }
        
        .notification {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}