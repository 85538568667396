.EventPage {
  background-color: #F7F8F4;
  
  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .event-page-content {
      padding: 80px 0;

      .previous-page-link {
        margin-bottom: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-family: Inter;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
      }

      .heading-one,
      .heading-two {
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .heading-three {
        font-family: Inter;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        text-transform: uppercase;
      }

      .event-cover {
        position: relative;
        margin-top: 80px;
        height: 420px;
        border-radius: 20px;
        overflow: hidden;

        .image-dimming {
          position: absolute;
          z-index: 1;
          border-radius: 20px;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }

        .event-image {
          display: block;
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }

        .event-title {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 30px;
          width: 100%;

          .cover-icon {
            display: block;
            width: 40px;
          }

          .heading-three_cover {
            padding: 0 80px;
            text-align: center;
            color: #FFFFFF;
          } 
        }

        .event-date {
          position: absolute;
          z-index: 1;
          bottom: 10%;
          width: 100%;
          padding: 0 80px;
          font-family: Inter;
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          color: #FFFFFF;
        }
      }

      .event-content {
        .event-content-top-row {
          padding-top: 80px;

          .event-basic-info {
            .basic-info-item {
              display: flex;
              align-items: center;
              column-gap: 15px;
              padding-bottom: 15px;
        
              .info-icon {
                display: block;
                width: 20px;
              }
        
              .paragraph {
                font-family: Inter;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
              }
        
              &:last-of-type {
                padding-bottom: 0;
              }
            }
          }
        }

        .event-content-bottom-row {
          display: flex;
          justify-content: space-between;
          padding-top: 80px;
  
          .event-details {
            button {
              margin-top: 65px;
            } 
          }
  
          .event-description {
            width: 50%;
            font-family: Inter;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: justify;
  
            div,
            p,
            span,
            a,
            ul > li,
            ol > li {
              background-color: transparent !important;
              font-family: Inter !important;
              font-size: 18px !important;
              line-height: 26px !important;
              text-align: justify !important;
              color: #3D4910 !important;
              word-wrap: normal !important;
            }
  
            div,
            p {
              margin: 0 !important;
              padding: 30px 0 0 !important;
              white-space: normal !important;
  
              &:first-child {
                padding-top: 0 !important;
              }
            }
  
            span {
              margin: 0 !important;
              padding: 0 !important;
            }
  
            a {
              margin: 0 !important;
              padding: 0 !important;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline !important;
              }
            }
  
            ul > li,
            ol > li {
              margin: 0 0 0 20px !important;
              padding: 0 !important;
              white-space: normal !important;
            }
          }
        }
      }

      .heading-two_form {
        padding-top: 80px;
      }

      .form-description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }

      .notification {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #C85252;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .event-page-content {
        padding: 70px 0;
  
        .previous-page-link {
          margin-bottom: 45px;
          font-size: 19px;
          line-height: 28px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 56px;
          line-height: 68px;
        }
  
        .heading-three {
          font-size: 35px;
          line-height: 42px;
        }
  
        .event-cover {
          margin-top: 70px;
          height: 380px;
  
          .event-title {
            .heading-three_cover {
              padding: 0 70px;
            } 
          }

          .event-date {
            padding: 0 70px;
            font-size: 28px;
            line-height: 36px;
          }
        }

        .event-content {
          .event-content-top-row {
            padding-top: 70px;

            .event-basic-info {
              .basic-info-item {
                .info-icon {
                  width: 18px;
                }
          
                .paragraph {
                  line-height: 24px;
                }
              }
            }
          }
  
          .event-content-bottom-row {
            padding-top: 70px;
    
            .event-details {
              button {
                margin-top: 57.5px;
              } 
            }
    
            .event-description {
              width: 55%;
              font-size: 17px;
              line-height: 24px;
    
              div,
              p,
              span,
              a,
              ul > li,
              ol > li {
                font-size: 17px !important;
                line-height: 24px !important;
              }
  
              div,
              p {
                padding: 25px 0 0 !important;
              }
            }
          }
        }
  
        .heading-two_form {
          padding-top: 70px;
        }
  
        .form-description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        .notification {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .event-page-content {
        padding: 60px 0;
  
        .previous-page-link {
          margin-bottom: 40px;
          font-size: 18px;
          line-height: 26px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 48px;
          line-height: 63px;
        }
  
        .heading-three {
          font-size: 30px;
          line-height: 36px;
        }
  
        .event-cover {
          margin-top: 60px;
          height: 340px;
          border-radius: 15px;
  
          .image-dimming {
            border-radius: 15px;
          }
  
          .event-title {
            row-gap: 25px;
  
            .heading-three_cover {
              padding: 0 65px;
            }
          }

          .event-date {
            padding: 0 65px;
            font-size: 24px;
            line-height: 32px;
          }
        }

        .event-content {
          .event-content-top-row {
            padding-top: 60px;

            .event-basic-info {
              .basic-info-item {
                .paragraph {
                  font-size: 16px;
                }
              }
            }
          }
  
          .event-content-bottom-row {
            padding-top: 60px;
    
            .event-details {
              button {
                margin-top: 52.5px;
              } 
            }
    
            .event-description {
              width: 57.5%;
              font-size: 16px;
              line-height: 23px;
    
              div,
              p,
              span,
              a,
              ul > li,
              ol > li {
                font-size: 16px !important;
                line-height: 23px !important;
              }
            }
          }
        }
  
        .heading-two_form {
          padding-top: 60px;
        }
  
        .form-description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        .notification {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .event-page-content {
        padding: 50px 0;
  
        .previous-page-link {
          margin-bottom: 35px;
          font-size: 17px;
          line-height: 24px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 40px;
          line-height: 58px;
        }
  
        .heading-three {
          font-size: 25px;
          line-height: 30px;
        }
  
        .event-cover {
          margin-top: 50px;
          height: 280px;
  
          .event-title {
            top: 15%;

            .heading-three_cover {
              padding: 0 60px;
            }
          }

          .event-date {
            padding: 0 60px;
            font-size: 20px;
            line-height: 28px;
          }
        }

        .event-content {
          .event-content-top-row {
            padding-top: 50px;

            .event-basic-info {
              .basic-info-item {
                .info-icon {
                  width: 16px;
                }
          
                .paragraph {
                  line-height: 22px;
                }
              }
            }
          }
  
          .event-content-bottom-row {
            padding-top: 50px;
    
            .event-details {
              button {
                margin-top: 47.5px;
              } 
            }
    
            .event-description {
              width: 60%;
              font-size: 15px;
              line-height: 22px;
    
              div,
              p,
              span,
              a,
              ul > li,
              ol > li {
                font-size: 15px !important;
                line-height: 22px !important;
              }
  
              div,
              p {
                padding: 20px 0 0 !important;
              }
            }
          }
        }
  
        .heading-two_form {
          padding-top: 50px;
        }
  
        .form-description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        .notification {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .event-page-content {
        padding: 40px 0;
  
        .previous-page-link {
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 22px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 32px;
          line-height: 48px;
        }
  
        .heading-three {
          font-size: 20px;
          line-height: 24px;
        }
  
        .event-cover {
          margin-top: 40px;
          height: 220px;
          border-radius: 10px;
  
          .image-dimming {
            border-radius: 10px;
          }
  
          .event-title {
            row-gap: 20px;
  
            .cover-icon {
              width: 30px;
            }
  
            .heading-three_cover {
              padding: 0 50px;
            }
          }

          .event-date {
            padding: 0 50px;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .event-content {
          .event-content-top-row {
            padding-top: 40px;

            .event-basic-info {
              .basic-info-item {
                column-gap: 10px;
                padding-bottom: 10px;
          
                .info-icon {
                  width: 14px;
                }
          
                .paragraph {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
  
          .event-content-bottom-row {
            padding-top: 40px;
    
            .event-details {
              button {
                margin-top: 40px;
              } 
            }
    
            .event-description {
              width: 65%;
              font-size: 14px;
              line-height: 20px;
    
              div,
              p,
              span,
              a,
              ul > li,
              ol > li {
                font-size: 14px !important;
                line-height: 20px !important;
              }
    
              div,
              p {
                padding: 15px 0 0 !important;
              }
            }
          }
        }
  
        .heading-two_form {
          padding-top: 40px;
        }
  
        .form-description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        .notification {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .event-page-content {
        padding: 30px 0;
  
        .previous-page-link {
          margin-bottom: 25px;
          font-size: 14px;
          line-height: 18px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 25px;
          line-height: 36px;
        }
  
        .heading-three {
          font-size: 17px;
          line-height: 21px;
        }
  
        .event-cover {
          margin-top: 30px;
          height: 240px;
  
          .event-title {
            .heading-three_cover {
              padding: 0 45px;
              font-size: 18px;
              line-height: 22px;
            }
          }

          .event-date {
            padding: 0 45px;
            line-height: 22px;
          }
        }

        .event-content {
          .event-content-top-row {
            padding-top: 30px;

            .event-basic-info {
              .basic-info-item {
                .paragraph {
                  font-size: 13px;
                  line-height: 18px;
                }
              }
            }
          }
  
          .event-content-bottom-row {
            padding-top: 30px;
    
            .event-details {
              button {
                margin-top: 30px;
              }
            }
    
            .event-description {
              width: 70%;
              font-size: 13px;
              line-height: 19px;
    
              div,
              p,
              span,
              a,
              ul > li,
              ol > li {
                font-size: 13px !important;
                line-height: 19px !important;
              }
            }
          }
        }
  
        .heading-two_form {
          padding-top: 30px;
        }
  
        .form-description {
          font-size: 14px;
          line-height: 20px;
        }
  
        .notification {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .event-page-content {
        padding: 20px 0;
  
        .previous-page-link {
          margin-bottom: 20px;
          font-size: 12px;
          line-height: 14px;
        }
  
        .heading-one,
        .heading-two {
          font-size: 18px;
          line-height: 24px;
        }
  
        .heading-three {
          font-size: 14px;
          line-height: 18px;
        }
  
        .event-cover {
          margin-top: 20px;
          height: 260px;
  
          .event-title {
            top: 10%;

            .heading-three_cover {
              padding: 0 40px;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .event-date {
            padding: 0 40px;
            line-height: 20px;
          }
        }

        .event-content {
          .event-content-top-row {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;

            .event-details {
              button {
                margin-top: 20px;
              }
            }

            .event-basic-info {
              .basic-info-item {
                column-gap: 7.5px;
                padding-bottom: 7.5px;
          
                .info-icon {
                  width: 12px;
                }
          
                .paragraph {
                  font-size: 12px;
                  line-height: 16px;
                }
              }
            }
          }
  
          .event-content-bottom-row {
            display: block;
            padding-top: 20px;

            .event-description {
              width: 100%;
              font-size: 12px;
              line-height: 18px;
    
              div,
              p,
              span,
              a,
              ul > li,
              ol > li {
                font-size: 12px !important;
                line-height: 18px !important;
              }
    
              div,
              p {
                padding: 10px 0 0 !important;
              }
            }
          }
        }
  
        .heading-two_form {
          padding-top: 20px;
        }
  
        .form-description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        .notification {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}