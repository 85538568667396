.button {
  border-radius: 26px;
  border: 1px solid #3D4910;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;

  a {
    display: block;
    padding: 15px 30px;
    text-decoration: none;
  }
}

.button_primary {
  background-color: #3D4910;

  a {
    color: #FFFFFF;
  }

  &:hover {
    background-color: transparent;

    a {
      color: #3D4910;
    }
  }
}

.button_secondary {
  background-color: transparent;

  a {
    color: #3D4910;
  }

  &:hover {
    background-color: #3D4910;

    a {
      color: #FFFFFF;
    }
  }
}

@media (max-width: 1440px) {
  .button {
    a {
      padding: 15px 25px;
    }
  }
}

@media (max-width: 1024px) {
  .button {
    border-radius: 25px;
    font-size: 14px;
    line-height: 18px;

    a {
      padding: 15px 20px;
    }
  }
}

@media (max-width: 834px) {
  .button {
    border-radius: 20px;

    a {
      padding: 10px 15px;
    }
  }
}

@media (max-width: 601px) {
  .button {
    border-radius: 19px;
    font-size: 12px;
    line-height: 16px;
  }
}