.phone-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  .title {
    position: relative;

    label {
      font-family: Inter;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: -29px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 22px;
      cursor: pointer;
    }
  }

  .phone-input-container {
    input[class="form-control "],
    input[class="form-control invalid"],
    input[class="form-control open "],
    input[class="form-control open invalid"] {
      width: 100%;
      min-height: 48px;
      border-radius: 24px;
      border: 1px solid #3D4910;
      padding: 10px 15px 10px 65px;
      font-family: Inter;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #3D4910;
    }

    input[class="form-control "] {
      &:hover {
        border: 1px solid #677C1A;
      }
    }
  
    input[class="form-control invalid"],
    input[class="form-control open invalid"] {
      border: 1px solid #C85252;
    }

    .flag-container {
      left: 24px;
      top: 1px;
      height: -webkit-calc(100% - 2px);
      height: -moz-calc(100% - 2px);
      height: -o-calc(100% - 2px);
      height: -ms-calc(100% - 2px);
      height: calc(100% - 2px);
      border: none;
      background-color: #FFFFFF;

      &.open {
        z-index: 3;
      }
    }

    input:disabled + .flag-container {
      background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
    }

    :disabled {
      background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
      cursor: default;
    }
  }

  .warning {
    position: absolute;
    top: 100%;
    padding: 5px 0 0 16px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C85252;
  }
}

@media (max-width: 1600px) {
  .phone-input-wrapper {
    margin-bottom: 29px;
  
    .title {
      label {
        font-size: 22px;
        line-height: 29px;
      }

      img {
        right: -26px;
        width: 20px;
      }
    }
  
    .phone-input-container {
      input[class="form-control "],
      input[class="form-control invalid"],
      input[class="form-control open "],
      input[class="form-control open invalid"] {
        min-height: 46px;
        border-radius: 23px;
        font-size: 17px;
        line-height: 24px;
      }
  
      .flag-container {
        left: 23px;
      }
    }
  
    .warning {
      font-size: 15px;
      line-height: 19px;
    }
  }
}

@media (max-width: 1440px) {
  .phone-input-wrapper {
    margin-bottom: 28px;

    .title {
      label {
        font-size: 21px;
        line-height: 28px;
      }

      img {
        right: -25px;
        width: 19px;
      }
    }
  
    .phone-input-container {
      input[class="form-control "],
      input[class="form-control invalid"],
      input[class="form-control open "],
      input[class="form-control open invalid"] {
        min-height: 45px;
        border-radius: 22.5px;
        font-size: 16px;
        line-height: 23px;
      }
  
      .flag-container {
        left: 22.5px;
      }
    }

    .warning {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media (max-width: 1280px) {
  .phone-input-wrapper {
    margin-bottom: 27px;
  
    .title {
      label {
        font-size: 20px;
        line-height: 27px;
      }

      img {
        right: -24px;
        width: 18px;
      }
    }
  
    .phone-input-container {
      input[class="form-control "],
      input[class="form-control invalid"],
      input[class="form-control open "],
      input[class="form-control open invalid"] {
        min-height: 44px;
        border-radius: 22px;
        font-size: 15px;
        line-height: 22px;
      }
  
      .flag-container {
        left: 22px;
      }
    }
  
    .warning {
      font-size: 13px;
      line-height: 17px;
    }
  }
}

@media (max-width: 1024px) {
  .phone-input-wrapper {
    margin-bottom: 25px;
  
    .title {
      label {
        font-size: 18px;
        line-height: 24px;
      }

      img {
        right: -21px;
        width: 16px;
      }
    }
  
    .phone-input-container {
      input[class="form-control "],
      input[class="form-control invalid"],
      input[class="form-control open "],
      input[class="form-control open invalid"] {
        min-height: 42px;
        border-radius: 21px;
        padding: 10px 15px 10px 60px;
        font-size: 14px;
        line-height: 20px;
      }
  
      .flag-container {
        left: 21px;
      }
    }
  
    .warning {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

@media (max-width: 834px) {
  .phone-input-wrapper {
    margin-bottom: 24px;
  
    .title {
      label {
        font-size: 16px;
        line-height: 22px;
      }

      img {
        right: -19px;
        width: 14px;
      }
    }
  
    .phone-input-container {
      input[class="form-control "],
      input[class="form-control invalid"],
      input[class="form-control open "],
      input[class="form-control open invalid"] {
        min-height: 41px;
        border-radius: 20.5px;
        font-size: 13px;
        line-height: 19px;
      }
  
      .flag-container {
        left: 20.5px;
      }
    }
  
    .warning {
      font-size: 11px;
      line-height: 14px;
    }
  }
}

@media (max-width: 601px) {
  .phone-input-wrapper {
    margin-bottom: 23px;
  
    .title {
      label {
        font-size: 14px;
        line-height: 20px;
      }

      img {
        right: -17px;
        width: 12px;
      }
    }
  
    .phone-input-container {
      input[class="form-control "],
      input[class="form-control invalid"],
      input[class="form-control open "],
      input[class="form-control open invalid"] {
        min-height: 40px;
        border-radius: 20px;
        font-size: 12px;
        line-height: 18px;
      }
  
      .flag-container {
        left: 20px;
      }
    }
  
    .warning {
      font-size: 10px;
      line-height: 13px;
    }
  }
}