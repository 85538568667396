.select-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  label {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    cursor: pointer;
  }

  .select-container {
    &.invalid > div:first-of-type {
      border: 1px solid #C85252;
    }

    .primaryControl {
      z-index: 3;
    }

    .secondaryControl {
      z-index: 2;
    }

    .primaryMenu {
      z-index: 2;
    }

    .secondaryMenu {
      z-index: 1;
    }
  }

  .warning {
    position: absolute;
    top: 100%;
    padding: 5px 0 0 16px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C85252;
  }
}

@media (max-width: 1600px) {
  .select-wrapper {
    margin-bottom: 29px;
  
    label {
      font-size: 22px;
      line-height: 29px;
    }
  
    .warning {
      font-size: 15px;
      line-height: 19px;
    }
  }
}

@media (max-width: 1440px) {
  .select-wrapper {
    margin-bottom: 28px;

    label {
      font-size: 21px;
      line-height: 28px;
    }

    .warning {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media (max-width: 1280px) {
  .select-wrapper {
    margin-bottom: 27px;
  
    label {
      font-size: 20px;
      line-height: 27px;
    }
  
    .warning {
      font-size: 13px;
      line-height: 17px;
    }
  }
}

@media (max-width: 1024px) {
  .select-wrapper {
    margin-bottom: 25px;
  
    label {
      font-size: 18px;
      line-height: 24px;
    }
  
    .warning {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

@media (max-width: 834px) {
  .select-wrapper {
    margin-bottom: 24px;
  
    label {
      font-size: 16px;
      line-height: 22px;
    }

    .indicatorsContainer {
      div {
        svg {
          height: 19px;
        }
      }
    }
  
    .warning {
      font-size: 11px;
      line-height: 14px;
    }
  }
}

@media (max-width: 601px) {
  .select-wrapper {
    margin-bottom: 23px;
  
    label {
      font-size: 14px;
      line-height: 20px;
    }

    .indicatorsContainer {
      div {
        svg {
          height: 18px;
        }
      }
    }
  
    .warning {
      font-size: 10px;
      line-height: 13px;
    }
  }
}