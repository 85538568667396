.info-message {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(61, 73, 16, 0.1);
  
  .modal-window {
    position: relative;
    max-width: 80%;
    padding: 40px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);

    .paragraph {
      font-family: Inter;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      white-space: pre-wrap;
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transform: translate(40%, -40%);
      -moz-transform: translate(40%, -40%);
      -o-transform: translate(40%, -40%);
      -ms-transform: translate(40%, -40%);
      transform: translate(40%, -40%);
      border-radius: 15px;
      border: none;
      padding: 8px;
      background-color: #3D4910;
      cursor: pointer;

      img {
        display: block;
        width: 14px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .info-message {
    .modal-window {
      .paragraph {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .info-message {
    .modal-window {
      padding: 35px;
  
      .paragraph {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .info-message {
    .modal-window {
      .paragraph {
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .info-message {
    .modal-window {
      padding: 30px;
  
      .paragraph {
        font-size: 14px;
        line-height: 20px;
      }

      .close {
        border-radius: 13px;
        padding: 7px;
  
        img {
          width: 12px;
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .info-message {
    .modal-window {
      padding: 25px;
  
      .paragraph {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
}

@media (max-width: 601px) {
  .info-message {
    .modal-window {
      padding: 20px;
  
      .paragraph {
        font-size: 12px;
        line-height: 18px;
      }

      .close {
        border-radius: 15px;
        padding: 8px;
  
        img {
          width: 14px;
        }
      }
    }
  }
}