.submit {
  border-radius: 26px;
  border: 1px solid #3D4910;
  padding: 15px 30px;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;
}

.submit_primary {
  background-color: #3D4910;
  color: #FFFFFF;

  &:disabled {
    background-color: #BDBDBD;
    color: #3D4910;
    cursor: not-allowed;
  }
}

.submit_secondary {
  background-color: transparent;
  color: #3D4910;

  &:disabled {
    background-color: #BDBDBD;
    color: #3D4910;
    cursor: not-allowed;
  }
}

@media (max-width: 1440px) {
  .submit {
    padding: 15px 25px;
  }
}

@media (max-width: 1024px) {
  .submit {
    border-radius: 25px;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 834px) {
  .submit {
    border-radius: 20px;
    padding: 10px 15px;
  }
}

@media (max-width: 601px) {
  .submit {
    border-radius: 19px;
    font-size: 12px;
    line-height: 16px;
  }
}