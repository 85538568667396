.service-list__item {
  width: -webkit-calc((100% - 90px)/4);
  width: -moz-calc((100% - 90px)/4);
  width: -o-calc((100% - 90px)/4);
  width: -ms-calc((100% - 90px)/4);
  width: calc((100% - 90px)/4);
  border-radius: 15px;
  padding: 40px;
  background-color: #F7F8F4;
  box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .service-list__item-top-row {
    display: flex;
    flex-direction: column;
    align-items: center;

    .service-list__icon {
      display: block; 
    }
  
    .service-list__title {
      padding: 20px 0 30px;
      font-family: Inter;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 1600px) {
  .service-list__item {
    padding: 40px 30px;
  }
}

@media (max-width: 1440px) {
  .service-list__item {
    width: -webkit-calc((100% - 60px)/3);
    width: -moz-calc((100% - 60px)/3);
    width: -o-calc((100% - 60px)/3);
    width: -ms-calc((100% - 60px)/3);
    width: calc((100% - 60px)/3);

    .service-list__item-top-row {
      .service-list__title {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .service-list__item {
    width: -webkit-calc((100% - 25px)/2);
    width: -moz-calc((100% - 25px)/2);
    width: -o-calc((100% - 25px)/2);
    width: -ms-calc((100% - 25px)/2);
    width: calc((100% - 25px)/2);
  }
}

@media (max-width: 834px) {
  .service-list__item {
    padding: 30px;

    .service-list__item-top-row {
      .service-list__title {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

@media (max-width: 601px) {
  .service-list__item {
    width: -webkit-calc((100% - 20px)/2);
    width: -moz-calc((100% - 20px)/2);
    width: -o-calc((100% - 20px)/2);
    width: -ms-calc((100% - 20px)/2);
    width: calc((100% - 20px)/2);
    padding: 25px 20px;

    .service-list__item-top-row {
      .service-list__title {
        padding: 20px 0 25px;
      }
    }
  }
}

@media (max-width: 430px) {
  .service-list__item {
    padding: 20px 15px;

    button {
      a {
        padding: 10px 12.5px;
      }
    }
  } 
}

@media (max-width: 390px) {
  .service-list__item {
    padding: 20px 12.5px;

    button {
      a {
        padding: 10px;
      }
    }
  } 
}