body {
  #bingc-passive {
    div.bingc-passive-overlay {
      div.bingc-passive-content {
        div.bingc-passive-get-phone-form {
          form.bingc-passive-get-phone-form {
            a.bingc-passive-phone-form-button {
              background: #677C1A !important;

              &:hover,
              &:active {
                background: #3D4910 !important;
              }
            }

            div.bingc-passive-get-phone-form-date-selection {
              div.bingc-passive-date-selection-select-hour,
              div.bingc-passive-date-selection-select-minutes {
                background: #677C1A !important;

                div.bingc-passive-date-selection-select-hour-list-of-hours-container,
                div.bingc-passive-date-selection-select-minutes-list-of-minutes-container {
                  ul.bingc-passive-date-selection-select-hour-list-of-hours,
                  ul.bingc-passive-date-selection-select-minutes-list-of-minutes {
                    li {
                      color: #3D4910 !important;

                      &:hover {
                        background: #677C1A !important;
                      }
                    }
                  }
                }

                &:hover {
                  background: #3D4910 !important;
                }
              }
              
              div.bingc-passive-date-selection-select-day {
                div.bingc-passive-date-selection-select-day-list-of-days-container {
                  ul.bingc-passive-date-selection-select-day-list-of-days {
                    li {
                      color: #3D4910 !important;

                      &:hover {
                        background: #677C1A !important;
                      }
                    }
                  }
                }

                &:hover {
                  background: #3D4910 !important;

                  svg.bingc-passive-date-selection-arrows {
                    path {
                      fill-opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #bingc-active {
    div.bingc-active-overlay {
      div.bingc-active-content {
        div.bingc-active-get-phone-form {
          form.bingc-active-get-phone-form {
            a.bingc-active-phone-form-button {
              background: #677C1A !important;

              &:hover,
              &:active {
                background: #3D4910 !important;
              }
            }

            div.bingc-active-get-phone-form-date-selection {
              div.bingc-active-date-selection-select-hour,
              div.bingc-active-date-selection-select-minutes {
                background: #677C1A !important;

                div.bingc-active-date-selection-select-hour-list-of-hours-container,
                div.bingc-active-date-selection-select-minutes-list-of-minutes-container {
                  ul.bingc-active-date-selection-select-hour-list-of-hours,
                  ul.bingc-active-date-selection-select-minutes-list-of-minutes {
                    li {
                      color: #3D4910 !important;

                      &:hover {
                        background: #677C1A !important;
                      }
                    }
                  }
                }

                &:hover {
                  background: #3D4910 !important;
                }
              }
            }
          }
        }

        div.bingc-we-will-call-you.bingc-offline-form {
          div.bingc-active-date-selection-select-day {
            div.bingc-active-date-selection-select-day-list-of-days-container {
              ul.bingc-active-date-selection-select-day-list-of-days {
                li {
                  color: #3D4910 !important;

                  &:hover {
                    background: #677C1A !important;
                  }
                }
              }
            }

            &:hover {
              background: #3D4910 !important;
            }
          }
        }
      }
    }
  }

  #bingc-phone-button {
    div.bingc-phone-button-tooltip {
      background: #677C1A !important;

      svg.bingc-phone-button-arrow {
        polyline {
          fill: #677C1A !important;
        }
      }

      &:hover {
        background: #3D4910 !important;

        svg.bingc-phone-button-arrow {
          polyline {
            fill: #3D4910 !important;
          }
        }
      }
    }

    svg.bingc-phone-button-circle {
      circle.bingc-phone-button-circle-inside {
        fill: #677C1A !important;
      }
    }

    &:hover {
      svg.bingc-phone-button-circle {
        circle.bingc-phone-button-circle-inside {
          fill: #3D4910 !important;
        }
      }
    }
  }
}