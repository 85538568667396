.search-form {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  transition-duration: 0.5s;
  width: 40%;
  border-radius: 0 0 15px 15px;
  padding: 20px 30px;
  background-color: #FFFFFF;
  box-shadow: none;

  &.active {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    box-shadow: 1px 0 14px rgba(125, 131, 102, 0.2);
  }

  button[type="submit"] {
    position: absolute;
    left: 30px;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    cursor: pointer;

    img {
      display: block;
      width: 20px;
    }
  }

  .ordinary-input-wrapper {
    margin-bottom: 0;
  }
}

@media (max-width: 1600px) {
  .search-form {
    button[type="submit"] {
      img {
        width: 18px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .search-form {
    button[type="submit"] {
      img {
        width: 16px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .search-form {
    button[type="submit"] {
      img {
        width: 14px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .search-form {
    width: 45%;
    border-radius: 0 0 10px 10px;
    padding: 15px 25px;
  
    button[type="submit"] {
      left: 25px;

      img {
        width: 12px;
      }
    }
  }
}

@media (max-width: 834px) {
  .search-form {
    padding: 15px 20px;

    button[type="submit"] {
      img {
        width: 14px;
      }
    }
  }
}

@media (max-width: 601px) {
  .search-form {
    width: 57.5%;
  }
}

@media (max-width: 430px) {
  .search-form {
    width: 70%;
  }
}