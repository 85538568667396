.textarea-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  label {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    border-radius: 24px;
    border: 1px solid #3D4910;
    padding: 20px 15px;    
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #3D4910;

    &::placeholder {
      font-family: Inter;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: rgba(61, 73, 16, 0.7);
    }

    &:hover {
      border: 1px solid #677C1A;
    }

    &:focus {
      outline: none;
      border: 1px solid #3D4910;
    }

    &.invalid {
      border: 1px solid #C85252;
    }
  }

  .warning {
    position: absolute;
    top: 100%;
    padding: 5px 0 0 16px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C85252;
  }
}

@media (max-width: 1600px) {
  .textarea-wrapper {
    margin-bottom: 29px;
  
    label {
      font-size: 22px;
      line-height: 29px;
    }
  
    textarea {
      border-radius: 23px;
      font-size: 17px;
      line-height: 24px;
  
      &::placeholder {
        font-size: 17px;
        line-height: 24px;
      }
    }
  
    .warning {
      font-size: 15px;
      line-height: 19px;
    }
  }
}

@media (max-width: 1440px) {
  .textarea-wrapper {
    margin-bottom: 28px;

    label {
      font-size: 21px;
      line-height: 28px;
    }
  
    textarea {
      border-radius: 22.5px;
      font-size: 16px;
      line-height: 23px;
  
      &::placeholder {
        font-size: 16px;
        line-height: 23px;
      }
    }

    .warning {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media (max-width: 1280px) {
  .textarea-wrapper {
    margin-bottom: 27px;
  
    label {
      font-size: 20px;
      line-height: 27px;
    }
  
    textarea {
      border-radius: 22px;
      font-size: 15px;
      line-height: 22px;
  
      &::placeholder {
        font-size: 15px;
        line-height: 22px;
      }
    }
  
    .warning {
      font-size: 13px;
      line-height: 17px;
    }
  }
}

@media (max-width: 1024px) {
  .textarea-wrapper {
    margin-bottom: 25px;
  
    label {
      font-size: 18px;
      line-height: 24px;
    }
  
    textarea {
      border-radius: 21px;
      font-size: 14px;
      line-height: 20px;
  
      &::placeholder {
        font-size: 14px;
        line-height: 20px;
      }
    }
  
    .warning {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

@media (max-width: 834px) {
  .textarea-wrapper {
    margin-bottom: 24px;
  
    label {
      font-size: 16px;
      line-height: 22px;
    }
  
    textarea {
      border-radius: 20.5px;
      font-size: 13px;
      line-height: 19px;
  
      &::placeholder {
        font-size: 13px;
        line-height: 19px;
      }
    }
  
    .warning {
      font-size: 11px;
      line-height: 14px;
    }
  }
}

@media (max-width: 601px) {
  .textarea-wrapper {
    margin-bottom: 23px;
  
    label {
      font-size: 14px;
      line-height: 20px;
    }
  
    textarea {
      border-radius: 20px;
      font-size: 12px;
      line-height: 18px;
  
      &::placeholder {
        font-size: 12px;
        line-height: 18px;
      }
    }
  
    .warning {
      font-size: 10px;
      line-height: 13px;
    }
  }
}