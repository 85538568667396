.ActivitiesPage {
  background-color: #F7F8F4;
  
  .wrapper {
    margin: auto;
    width: 80%;
    max-width: 1400px;

    .activities-page-content {
      padding: 80px 0;

      .previous-page-link {
        font-family: Inter;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-decoration: none;
        text-transform: uppercase;
      }
      
      .heading-one {
        padding-top: 50px;
        font-family: Inter;
        font-weight: 800;
        font-size: 64px;
        line-height: 78px;
        text-transform: uppercase;
      }

      .description {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }

      .notification {
        padding-top: 40px;
        font-family: Inter;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #C85252;
      }
    }
  }

  @media (max-width: 1600px) {
    .wrapper {
      .activities-page-content {
        padding: 70px 0;
  
        .previous-page-link {
          font-size: 19px;
          line-height: 28px;
        }
  
        .heading-one {
          padding-top: 45px;
          font-size: 56px;
          line-height: 68px;
        }
  
        .description {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
  
        .notification {
          padding-top: 35px;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .wrapper {
      .activities-page-content {
        padding: 60px 0;
  
        .previous-page-link {
          font-size: 18px;
          line-height: 26px;
        }
  
        .heading-one {
          padding-top: 40px;
          font-size: 48px;
          line-height: 63px;
        }
  
        .description {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
  
        .notification {
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .wrapper {
      .activities-page-content {
        padding: 50px 0;
  
        .previous-page-link {
          font-size: 17px;
          line-height: 24px;
        }
  
        .heading-one {
          padding-top: 35px;
          font-size: 40px;
          line-height: 58px;
        }
  
        .description {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
  
        .notification {
          padding-top: 25px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      .activities-page-content {
        padding: 40px 0;
  
        .previous-page-link {
          font-size: 16px;
          line-height: 22px;
        }
  
        .heading-one {
          padding-top: 30px;
          font-size: 32px;
          line-height: 48px;
        }
  
        .description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
  
        .notification {
          padding-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .wrapper {
      width: 90%;

      .activities-page-content {
        padding: 30px 0;
  
        .previous-page-link {
          font-size: 14px;
          line-height: 18px;
        }
  
        .heading-one {
          padding-top: 25px;
          font-size: 25px;
          line-height: 36px;
        }
  
        .description {
          font-size: 14px;
          line-height: 20px;
        }
  
        .notification {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 601px) {
    .wrapper {
      .activities-page-content {
        padding: 20px 0;
  
        .previous-page-link {
          font-size: 12px;
          line-height: 14px;
        }
  
        .heading-one {
          padding-top: 20px;
          font-size: 18px;
          line-height: 24px;
        }
  
        .description {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
  
        .notification {
          padding-top: 15px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}