.article-comment-form {
  display: flex;
  flex-direction: column;
  padding-top: 65px;

  .article-comment-form-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 50px;

    .ordinary-input-wrapper {
      width: 48%;
    }

    .textarea-wrapper {
      width: 100%;
    }
  }

  button[type="submit"] {
    align-self: center;
    margin-top: 50px;
  }
}

@media (max-width: 1600px) {
  .article-comment-form {
    padding-top: 55px;
  
    .article-comment-form-fields {
      row-gap: 40px;
    }
  
    button[type="submit"] {
      margin-top: 40px;
    }
  }
}

@media (max-width: 1440px) {
  .article-comment-form {
    padding-top: 45px;
  
    .article-comment-form-fields {
      row-gap: 35px;
    }
  
    button[type="submit"] {
      margin-top: 35px;
    }
  }
}

@media (max-width: 1280px) {
  .article-comment-form {
    padding-top: 35px;
  
    .article-comment-form-fields {
      row-gap: 30px;
    }
  
    button[type="submit"] {
      margin-top: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .article-comment-form {
    padding-top: 20px;
  
    .article-comment-form-fields {
      row-gap: 20px;
    }
  
    button[type="submit"] {
      margin-top: 20px;
    }
  }
}

@media (max-width: 834px) {
  .article-comment-form {
    .article-comment-form-fields {
      row-gap: 10px;
    }
  
    button[type="submit"] {
      margin-top: 10px;
    }
  }
}

@media (max-width: 601px) {
  .article-comment-form {
    padding-top: 15px;
  
    .article-comment-form-fields {
      row-gap: 0;
      
      .ordinary-input-wrapper {
        width: 100%;
      }
    }
  
    button[type="submit"] {
      margin-top: 0;
    }
  }
}